import { firestore } from '../../firebaseConfig';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, query, where, doc } from 'firebase/firestore';

const orgMemberCollection = collection(firestore, 'users');

export const createMember = async (data) => {
  const docRef = await addDoc(orgMemberCollection, data);
  return docRef.id;
};


export const updateMember = async (id, data) => {
  const docRef = doc(firestore, 'users', id);
  await updateDoc(docRef, data);
};

export const deleteMember = async (id) => {
  const docRef = doc(firestore, 'users', id);
  await deleteDoc(docRef);
};

export const fetchMembers = async (ownerId) => {
  const q = query(orgMemberCollection, where("ownerId", "==", ownerId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const fetchMembersByOrgId = async (orgId) => {
    const q = query(orgMemberCollection, where("orgId", "==", orgId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };
  