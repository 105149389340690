import apiClient from './index';

export const fetchUsers = async () => {
  const response = await apiClient.get('/users');
  return response.data;
};

export const createUser = async (data) => {
  const response = await apiClient.post('/users/create-user', data);
  return response.data;
};

export const uploadCsv = async (data) => {
    const response = await apiClient.post('/users/upload-csv',data);
    return response.data;
  };

  export const updateAllStatusByIds = async (data) => {
    const response = await apiClient.put('/users/status/all', data);
    return response.data;
  };

  export const updateMainUser = async (data) => {
    const response = await apiClient.post(`/users/update-user`, data);
    return response.data;
  };

export const updateUser = async (id, data) => {
  const response = await apiClient.put(`/users/${id}`, data);
  return response.data;
};

export const deleteUser = async (id) => {
  const response = await apiClient.delete(`/users/${id}`);
  return response.data;
};

// Add other user-related API calls here
