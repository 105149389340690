import apiClient from './index';

export const fetchActions = async (page,size,orgId) => {
  const response = await apiClient.get(`/actions?page=${page}&size=${size}&orgId=${orgId}`);
  return response.data;
};

export const fetchActionById = async (id) => {
  const response = await apiClient.get(`/actions/${id}`);
  return response.data;
};

export const createAction = async (data) => {
  const response = await apiClient.post('/actions', data);
  return response.data;
};

export const updateAction = async (data) => {
  const response = await apiClient.put('/actions', data);
  return response.data;
};


