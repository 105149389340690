// src/CreateUserForm.js
import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";

const CreateUserForm = ({ visible, onCreate, onCancel, user }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
  }, [user, form]);

  return (
    <Modal
      visible={visible}
      title={user ? "Edit User" : "Create a new user"}
      okText={user ? "Update" : "Create"}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_user_form"
        onFinish={(values) => {
          onCreate(values);
          form.resetFields();
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the user's name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Please enter the user's phone number" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserForm;
