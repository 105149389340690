import apiClient from './index';

export const createNewCall = async (data) => {
  const response = await apiClient.post(`/calls/new-call`,data);
  return response.data;
};

export const fetchCalls = async (page,size) => {
  const response = await apiClient.get(`/calls?page=${page}`);
  return response.data;
};

export const getRecording = async (id) => {
  const response = await apiClient.get(`/calls/${id}/recording`);
  return response.data;
};

export const fetchCallsByOrgId = async (orgId,type) => {
  const response = await apiClient.get(`/calls/calls-by-orgId?orgId=${orgId}&type=${type}`);
  return response.data;
};


export const getCallById = async (id) => {
  const response = await apiClient.get(`/calls/getcall/${id}`);
  return response.data;
};


export const getPromptById = async (id) => {
  const response = await apiClient.get(`/prompts/${id}`);
  return response.data.data;
};