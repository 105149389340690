// src/api/userApi.js
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import FirestoreORM from '../../orm/firestoreOrm';
import { message } from 'antd';

const usersORM = new FirestoreORM('users');

// export const createUser = async (userData) => {
//   return await usersORM.create(userData);
// };

export const createUser = async (user) => {
  const userDoc = doc(firestore, "users", user.id);
  const userSnapshot = await getDoc(userDoc);

  if (userSnapshot.exists()) {
    console.log("User already exists");
    message.error("User already exists")
    return;
  }

  await setDoc(userDoc, user);
  console.log("User created successfully");
};

// export const createUser = async (userData) => {
//   try {
//     await setDoc(doc(firestore, 'users', userData.id), userData);
//     console.log('User created successfully');
//   } catch (error) {
//     console.error('Error creating user:', error);
//     throw new Error('Failed to create user');
//   }
// };

export const getUser = async (userId) => {
  return await usersORM.read(userId);
};

export const updateUser = async (userId, userData) => {
  await usersORM.update(userId, userData);
};

export const deleteUser = async (userId) => {
  await usersORM.delete(userId);
};

export const getAllUsers = async () => {
  return await usersORM.findAll();
};

export const getUsersByField = async (fieldName, value) => {
  return await usersORM.findByField(fieldName, value);
};
