// UnVerifiedPage.js
import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { CheckCircleOutlined } from "@ant-design/icons";

const UnVerifiedPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <div className="unverified-container">
      <div className="unverified-content">
        <CheckCircleOutlined style={{ fontSize: "64px", color: "#52c41a" }} />
        <h1 className="text-2xl font-semibold mt-4">
          Thank You for Trusting Us!
        </h1>
        <p className="mt-2">
          We have received your email. We will contact you soon.
        </p>
        <div className="mt-6">
          <Button type="primary" danger onClick={() => logout()}>
            Logout
          </Button>
        </div>
      </div>
      <style jsx>{`
        .unverified-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          backdrop-filter: blur(4px);
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.7);
          z-index: 9999;
        }
        .unverified-content {
          text-align: center;
          background: white;
          padding: 40px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
      `}</style>
    </div>
  );
};

export default UnVerifiedPage;
