import { Spin, Typography, message } from "antd";
import React, { useState, useEffect } from "react";
import { checkoutSession } from "../utils/helper";
import { useAuth } from "../AuthContext";

const PricingPlans = ({ plans }) => {
  const { userData, organization } = useAuth();
  const [paying, setPaying] = useState(false);
  const [sortedPlans, setSortedPlans] = useState([]);

  useEffect(() => {
    // Sort the plans based on rank before rendering
    const sorted = [...plans].sort(
      (a, b) => (a.rank || Infinity) - (b.rank || Infinity)
    );
    setSortedPlans(sorted);
  }, [plans]);

  return (
    <div className=" text-black flex items-center justify-center">
      <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        {sortedPlans?.map((item, index) => {
          let perMin = item.price > 0 ? item.price / item.allocateMinutes : 0;
          return (
            <div
              key={index}
              className="bg-white p-6 rounded-lg text-center border"
            >
              <h2 className="text-2xl font-semibold mb-4">{item.name}</h2>
              <Typography.Text className="text-sm mb-4 block " type="secondary">
                {item.description}
              </Typography.Text>
              <p className="text-4xl font-bold mb-4">
                {item.price == 0 ? "Free" : ""}
                {item.price < 0 && "Let's discuss"}
                {item.price > 0 && perMin + "€/min"}
              </p>

              {item.price == 0 ? (
                <button
                  disabled
                  className="bg-gray-400 text-white px-6 py-2 rounded-lg mb-4"
                >
                  Subscribed
                </button>
              ) : (
                <button
                  loading={paying}
                  disabled={paying}
                  onClick={() => {
                    setPaying(true);
                    if (paying) return;
                    if (!organization?.id && !userData?.orgId) {
                      message.error("Please refresh once");
                      setPaying(false);
                      return;
                    } else {
                      if (item.price < 0) {
                        window.location.href =
                          "https://calendly.com/agentel/meeting";
                        setPaying(false);
                        return;
                      } else {
                        checkoutSession(
                          {
                            name: item.name,
                            price: item.price,
                          },
                          organization?.id || userData?.orgId,
                          item.id,
                          userData?.id
                        );
                      }
                    }
                  }}
                  className="bg-black text-white px-6 py-2 rounded-lg mb-4"
                >
                  {paying ? (
                    <Spin />
                  ) : (
                    <>
                      {organization?.planId === item?.id
                        ? "Upgrade Now"
                        : "Start now"}
                    </>
                  )}
                </button>
              )}
              {item.allocateMinutes > 30 ? (
                <Typography.Text className="block font-bold text-lg">
                  {item.allocateMinutes} Minutes
                </Typography.Text>
              ) : (
                <></>
              )}
              <ul className="text-left space-y-2">
                {item.features.map((ft, ftIndex) => {
                  return <li key={ftIndex}>{ft}</li>;
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingPlans;
