import { firestore } from '../../firebaseConfig';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';

const permissionCollection = collection(firestore, 'permissions');

export const createPermission = async (data) => {
  const docRef = doc(permissionCollection, data.id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    throw new Error("Permission with this ID already exists");
  }
  await setDoc(docRef, data);
  return docRef.id;
};


export const updatePermission = async (id, data) => {
  const docRef = doc(firestore, 'permissions', id);
  await updateDoc(docRef, data);
};

export const deletePermission = async (id) => {
  const docRef = doc(firestore, 'permissions', id);
  await deleteDoc(docRef);
};

export const fetchPermissions = async () => {
  const querySnapshot = await getDocs(permissionCollection);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};
