import React, { useState } from "react";
import { Form, Input, Button, Select, Tabs, message } from "antd";
import GlobalModal from "../../components/GlobalModal";
import { phoneNumbersCreate } from "../../api/phoneNumbers";
import countries from "../../data/countries.json"; // Adjust the path as necessary
import { useAuth } from "../../AuthContext";
import Label from "../../components/Label";

const { TabPane } = Tabs;
const { Option } = Select;

const ImportPhoneNumberModal = ({ fetchNumbers, type, text }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("twilio");
  const [selectedCountry, setSelectedCountry] = useState(
    countries[0].dial_code
  ); // Default to the first country
  const { userData } = useAuth();
  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleImport = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const requestData = {
        provider: activeTab,
        number: "+" + values.phoneNumber,
        name: values.label,
      };

      if (activeTab === "twilio") {
        requestData.twilioAccountSid = values.accountSID;
        requestData.twilioAuthToken = values.authToken;
      } else if (activeTab === "vonage") {
        requestData.apiKey = values.apiKey;
        requestData.apiSecret = values.apiSecret;
      }

      await phoneNumbersCreate(requestData, userData.orgId);
      if (fetchNumbers) {
        fetchNumbers();
      }
      setLoading(false);
      setVisible(false);
      message.success("Phone number imported successfully!");
    } catch (error) {
      console.error(
        "Failed to import phone number:",
        error.response?.data?.error
      );
      setLoading(false);
      message.error(
        error.response?.data?.error || "Failed to import phone number."
      );
    }
  };

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Twilio" key="twilio">
          <Form requiredMark={false} form={form} layout="vertical">
            <Form.Item
              name="country"
              label={<Label required>Country</Label>}
              rules={[{ required: true, message: "Please select a country" }]}
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.dial_code}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={<Label required>Twilio Phone Number</Label>}
              rules={[
                { required: true, message: "Please enter the phone number" },
              ]}
            >
              <Input
                placeholder="(555) 000-0000"
                addonBefore={selectedCountry}
              />
            </Form.Item>
            <Form.Item
              name="accountSID"
              label={<Label required>Twilio Account SID</Label>}
              rules={[
                {
                  required: activeTab === "twilio",
                  message: "Please enter the account SID",
                },
              ]}
            >
              <Input placeholder="xxxxx" />
            </Form.Item>
            <Form.Item
              name="authToken"
              label={<Label required>Twilio Auth Token</Label>}
              rules={[
                {
                  required: activeTab === "twilio",
                  message: "Please enter the auth token",
                },
              ]}
            >
              <Input placeholder="xxxxx" />
            </Form.Item>
            <Form.Item
              name="label"
              label={<Label>Label</Label>}
              rules={[{ required: true, message: "Please enter a label" }]}
            >
              <Input placeholder="xxxxxx" />
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Vonage" key="vonage">
          <Form form={form} layout="vertical">
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Please select a country" }]}
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.dial_code}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Vonage Phone Number"
              rules={[
                { required: true, message: "Please enter the phone number" },
              ]}
            >
              <Input addonBefore={selectedCountry} />
            </Form.Item>
            <Form.Item
              name="apiKey"
              label="API Key"
              rules={[
                {
                  required: activeTab === "vonage",
                  message: "Please enter the API key",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="apiSecret"
              label="API Secret"
              rules={[
                {
                  required: activeTab === "vonage",
                  message: "Please enter the API secret",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="label"
              label="Label"
              rules={[{ required: true, message: "Please enter a label" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ImportPhoneNumberModal;
