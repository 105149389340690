import { firestore } from "../../firebaseConfig";
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, query, where } from "firebase/firestore";

const subscriptionsCollection = collection(firestore, 'subscriptions');

export const createSubscription = async (subscription) => {
  const docRef = await addDoc(subscriptionsCollection, subscription);
  return { id: docRef.id, ...subscription };
};

export const updateSubscription = async (id, subscription) => {
  const subscriptionDoc = doc(subscriptionsCollection, id);
  await updateDoc(subscriptionDoc, subscription);
};

export const deleteSubscription = async (id) => {
  const subscriptionDoc = doc(subscriptionsCollection, id);
  await deleteDoc(subscriptionDoc);
};

export const fetchSubscriptionsByOrganizationId = async (organizationId) => {
  const q = query(subscriptionsCollection, where("organizationId", "==", organizationId));
  const subscriptionsSnapshot = await getDocs(q);
  return subscriptionsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};
