import React, { useEffect, useState } from 'react';
import { Card, Col, Empty, Row, Spin, Typography } from 'antd';

const AgentStats = ({ data, loading }) => {
  const [agentsData, setAgentsData] = useState([]);

  useEffect(() => {
    if (!loading && data?.length) {
      const agents = {};

      data?.forEach(call => {
        if (!agents[call.agentPhoneNumber]) {
          agents[call.agentPhoneNumber] = {
            totalDuration: 0,
            totalCalls: 0,
            endedCalls: 0,
            name:""
          };
        }

        agents[call.agentPhoneNumber].totalDuration += call.duration;
        agents[call.agentPhoneNumber].totalCalls += 1;
        agents[call.agentPhoneNumber].name = call?.agent?.name
        if (call.status === 'ended') {
          agents[call.agentPhoneNumber].endedCalls += 1;
        }
      });

      const agentsArray = Object.keys(agents).map(agentPhoneNumber => ({
        agentPhoneNumber,
        ...agents[agentPhoneNumber],
      }));

      setAgentsData(agentsArray);
    }
  }, [data, loading]);

  return (
    <div className="w-full">
      <h2 className="text-2xl font-semibold mb-6">Best Performing Agent</h2>
      {loading ? (
        <div className="flex justify-center items-center w-full h-[300px]">
          <Spin size="large" />
        </div>
      ) : (
        <Row >

          {agentsData?.length === 0 && <Typography.Text type="secondary" className='block text-center w-full'>Not Enough Data</Typography.Text>}
          {agentsData?.map(agent => (
            <Col key={agent.agentPhoneNumber}>
              <Card className="shadow-lg" title={`${agent?.name}`} bordered={false}>
                <p className="font-medium mb-4 border-b-1"><strong>Used minutes:</strong> {agent.totalDuration.toFixed(2)} minutes</p>
                <p className="font-medium mb-4 border-b-1"><strong>Total Calls:</strong> {agent.totalCalls}</p>
                <p className="font-medium mb-4 border-b-1"><strong>Completed Calls:</strong> {agent.endedCalls}</p>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default AgentStats;
