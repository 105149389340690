import React, { useState } from "react";
import { Button, Card, Divider, Form, Input, message, Typography } from "antd";
import { useAuth } from "../../AuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import AvatarIcon from "../../assets/avatarLogin.png";
import MailLine from "../../assets/mail-line.png";
import lockLine from "../../assets/lockLine.png";
import Label from "../../components/Label";

const Login = () => {
  const { loginWithGoogle } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleEmailLogin = async (values) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      message.success("Login successful");
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging in with email:", error);
      message.error(
        "Login failed. Please check your email and password and try again."
      );
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center  bg-white w-full h-full">
      <div
        className="w-full max-w-sm p-6 rounded-lg"
        style={{
          border: "1px solid #E1E4EA",
        }}
      >
        <div className="flex flex-col items-center justify-between w-full">
          <img className="w-14 h-14 block" src={AvatarIcon} />
          <Typography.Text className="block text-2xl font-medium mt-2">
            Login to your account
          </Typography.Text>
        </div>
        <Divider />
        <Form requiredMark={false} layout="vertical" onFinish={handleEmailLogin}>
          <Form.Item
            name="email"
            label={<Label>Email Address</Label>}
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input
              placeholder="hello@alignui.com"
              prefix={<img className="w-4 h-4 block" src={MailLine} />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={<Label>Password</Label>}
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password
              placeholder="• • • • • • • • • • "
              prefix={<img className="w-4 h-4 block" src={lockLine} />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              htmlType="submit"
              loading={loading}
              block
              className="bg-black text-white rounded-lg "
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
