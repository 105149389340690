import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Modal, Typography, Button } from "antd";
import {
  FiUser,
  FiPhone,
  FiCreditCard,
  FiSettings,
  FiHelpCircle,
  FiLayers,
  FiChevronDown,
  FiChevronRight,
  FiUsers,
  FiBook,
  FiFileText,
  FiLogOut,
} from "react-icons/fi";
import { useAuth } from "../AuthContext";

const Sidebar = () => {
  const [outgoingOpen, setOutgoingOpen] = useState(false);
  const [incomingOpen, setIncomingOpen] = useState(false);
  const [developersOpen, setDevelopersOpen] = useState(false);
  const [organizationsOpen, setOrganizationsOpen] = useState(false);
  const { userData, organization, logout } = useAuth();
  const userRole = userData.roleId;

  const confirmLogout = () => {
    Modal.confirm({
      title: "Confirm Logout",
      content: "Are you sure you want to logout?",
      okText: "Yes",
      cancelText: "No",
      onOk: logout,
    });
  };


  
  const credRemain = organization?.usedMinutes;
  const totalAllocatedMinutes =
    organization?.freePlanMinutes + organization?.totalAllocatedMinutes;
  const prcnt = (credRemain / totalAllocatedMinutes) * 100;

  return (
    <div className="h-screen bg-white w-full p-4 border-r-[1px] border-gray-100 flex flex-col">
      <div className="flex-1 overflow-y-auto">
        <div className="flex items-center mb-6">
          <div className="w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center text-gray-600">
            {organization?.name[0]?.toUpperCase()}
          </div>
          <div className="ml-3 text-lg font-semibold">{organization?.name}</div>
        </div>
        <div className="mb-4">
          <div className="text-gray-500 mb-2">Create</div>
          <NavLink
            to="/agents"
            className="flex items-center px-4 py-2 rounded-md mb-2"
            activeClassName="bg-gray-200 text-gray-800"
          >
            <FiUser className="mr-3" />
            Agent
          </NavLink>
          <NavLink
            to="/coming-soon"
            className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
            activeClassName="bg-gray-200 text-gray-800"
          >
            <FiLayers className="mr-3" />
            Campaign
          </NavLink>
          <NavLink
            to="/coming-soon"
            className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
            activeClassName="bg-gray-200 text-gray-800"
          >
            <FiLayers className="mr-3" />
            Sequence
          </NavLink>
        </div>
        <div className="mb-4">
          <div className="text-gray-500 mb-2">Calls</div>
          <div
            className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600 cursor-pointer"
            onClick={() => setOutgoingOpen(!outgoingOpen)}
          >
            <FiPhone className="mr-3" />
            Outgoing calls
            {outgoingOpen ? (
              <FiChevronDown className="ml-auto" />
            ) : (
              <FiChevronRight className="ml-auto" />
            )}
          </div>
          <div
            className={`overflow-hidden mt-2 transition-all duration-300 ${
              outgoingOpen ? "max-h-40" : "max-h-0"
            }`}
          >
            <div className="ml-4 border-l-[1px] border-gray-200 pl-2">
              <NavLink
                to="/calls/outbound"
                className="flex items-center px-4 py-2 rounded-md mb-2"
                activeClassName="bg-gray-200 text-gray-800"
              >
                Outgoing Calls
              </NavLink>
              <NavLink
                to="/organizations/contacts"
                className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                activeClassName="bg-gray-200 text-gray-800"
              >
                Contacts
              </NavLink>
              {/* <NavLink
                to="/coming-soon"
                className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                activeClassName="bg-gray-200 text-gray-800"
              >
                Sequences
              </NavLink>
              <NavLink
                to="/coming-soon"
                className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                activeClassName="bg-gray-200 text-gray-800"
              >
                Campaigns
              </NavLink> */}
            </div>
          </div>
          <div
            className="flex items-center mt-2 px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600 cursor-pointer"
            onClick={() => setIncomingOpen(!incomingOpen)}
          >
            <FiPhone className="mr-3" />
            Incoming calls
            {incomingOpen ? (
              <FiChevronDown className="ml-auto" />
            ) : (
              <FiChevronRight className="ml-auto" />
            )}
          </div>
          <div
            className={`overflow-hidden mt-2 transition-all duration-300 ${
              incomingOpen ? "max-h-40" : "max-h-0"
            }`}
          >
            <div className="ml-4 border-l-[1px] border-gray-200 pl-2">
              <NavLink
                to="/calls/inbound"
                className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                activeClassName="bg-gray-200 text-gray-800"
              >
                Incoming Calls
              </NavLink>
            </div>
          </div>
        </div>
        {userRole === "owner" && (
          <>
            <div className="mb-4">
              <div className="text-gray-500 mb-2">Organizations</div>
              <div
                className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600 cursor-pointer"
                onClick={() => setOrganizationsOpen(!organizationsOpen)}
              >
                Organizations
                {organizationsOpen ? (
                  <FiChevronDown className="ml-auto" />
                ) : (
                  <FiChevronRight className="ml-auto" />
                )}
              </div>
              <div
                className={`overflow-hidden mt-2 transition-all duration-300 ${
                  organizationsOpen ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="ml-4 border-l-[1px] border-gray-200 pl-2">
                  <NavLink
                    to="/organizations"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    My Organization
                  </NavLink>
                  <NavLink
                    to="/organizations/verifications"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    Requests
                  </NavLink>
                  <NavLink
                    to="/organizations/list"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    All Organizations
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="text-gray-500 mb-2">Developers</div>
              <div
                className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600 cursor-pointer"
                onClick={() => setDevelopersOpen(!developersOpen)}
              >
                <FiFileText className="mr-3" />
                Developers
                {developersOpen ? (
                  <FiChevronDown className="ml-auto" />
                ) : (
                  <FiChevronRight className="ml-auto" />
                )}
              </div>
              <div
                className={`overflow-hidden mt-2 transition-all duration-300 ${
                  developersOpen ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="ml-4 border-l-[1px] border-gray-200 pl-2">
                  <NavLink
                    to="/permissions"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    Permissions
                  </NavLink>
                  <NavLink
                    to="/roles"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    Roles
                  </NavLink>
                  <NavLink
                    to="/plans"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    Plans
                  </NavLink>

                  <NavLink
                    to="/settings"
                    className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
                    activeClassName="bg-gray-200 text-gray-800"
                  >
                    Settings
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}

        {userRole !== "owner" && (
          <div className="mb-4">
            <NavLink
              to="/organizations"
              className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
              activeClassName="bg-gray-200 text-gray-800"
            >
              <FiUsers className="mr-3" />
              Organization
            </NavLink>
          </div>
        )}
      </div>
      <div className="bg-gray-100 p-4 rounded-md mb-4">
        <div className="text-black font-semibold mb-2">Credits remaining</div>
        <div className="w-full bg-gray-200 rounded-full h-1 mb-2">
          <div
            className="bg-black h-1 rounded-full"
            style={{ width: `${prcnt}%` }}
          ></div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-gray-500 text-xs">
          {/* {credRemain?.toFixed(2)}m/ */}
           {totalAllocatedMinutes?.toFixed(2)}m
          </div>
          <Link to="/pricing">
          <button  className="text-black underline">Upgrade</button>
          </Link>
        </div>
      </div>
      <div>
        <div
          className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600 cursor-pointer"
          onClick={confirmLogout}
        >
          <FiLogOut className="mr-3" />
          Logout
        </div>
        <a
          href="https://calendly.com/agentel/meeting"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-4 py-2 rounded-md hover:bg-gray-100 text-gray-600"
        >
          <FiHelpCircle className="mr-3" />
          Ask for help
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
