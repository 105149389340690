import { collection, doc, setDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

export const createOrganization = async (organization) => {
  const orgRef = doc(collection(firestore, 'organizations'));
  await setDoc(orgRef, organization);
  return orgRef.id;
};

export const fetchOrganizationsByOwner = async (ownerId) => {
  const q = query(collection(firestore, 'organizations'));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const updateOrganization = async (orgId, data) => {
  const orgRef = doc(firestore, 'organizations', orgId);
  await updateDoc(orgRef, data);
};
