import React from 'react';
import { Button, Select, Avatar } from 'antd';
import { PlayCircleOutlined, DownOutlined } from '@ant-design/icons';

const { Option } = Select;

const agentImage = 'https://test.voctiv.com/wp-content/uploads/2024/05/Beauty-salon-owner-that-uses-AI-Call-assistant-to-handle-her-phone-calls_small.png';

const NextStep = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center" style={{ background: 'linear-gradient(to bottom, #f0f2f5, #001628)' }}>
      <div className="flex items-center justify-between mb-8 w-full max-w-5xl">
        <div className="text-center mx-4">
          <div className="relative bg-white p-4 rounded-lg shadow-md h-64 flex flex-col items-center justify-between">
            <div className="font-semibold text-lg mb-2">Select 1st Agent</div>
            <Avatar src={agentImage} size={100} className="mb-2" />
            <div className="font-semibold">Hélène</div>
            <div className="text-gray-600 text-sm mb-4">My expertise is hospitality</div>
            <Select defaultValue="Hélène" className="w-full" suffixIcon={<DownOutlined />}>
              <Option value="Hélène">Hélène</Option>
              <Option value="Agent 1">Agent 1</Option>
              <Option value="Agent 2">Agent 2</Option>
            </Select>
          </div>
        </div>
        <div className="text-center mx-4">
          <div className="relative bg-white p-4 rounded-lg shadow-md h-64 flex flex-col items-center justify-between">
            <div className="font-semibold text-lg mb-2">Select 1st Action</div>
            <Select defaultValue="Transfer call" className="w-full" suffixIcon={<DownOutlined />}>
              <Option value="Transfer call">Transfer call</Option>
              <Option value="Other action">Other action</Option>
            </Select>
          </div>
        </div>
        <div className="text-center mx-4">
          <div className="relative bg-white p-4 rounded-lg shadow-md h-64 flex flex-col items-center justify-between">
            <div className="font-semibold text-lg mb-2">Select 2nd Agent</div>
            <Avatar src={agentImage} size={100} className="mb-2" />
            <div className="font-semibold">John</div>
            <div className="text-gray-600 text-sm mb-4">My expertise is hospitality</div>
            <Select defaultValue="John" className="w-full" suffixIcon={<DownOutlined />}>
              <Option value="John">John</Option>
              <Option value="Agent 1">Agent 1</Option>
              <Option value="Agent 2">Agent 2</Option>
            </Select>
          </div>
        </div>
        <div className="text-center mx-4">
          <div className="relative bg-white p-4 rounded-lg shadow-md h-64 flex flex-col items-center justify-between">
            <div className="font-semibold text-lg mb-2">Select 2nd Action</div>
            <Select defaultValue="Calendly" className="w-full" suffixIcon={<DownOutlined />}>
              <Option value="Calendly">Calendly</Option>
              <Option value="Other action">Other action</Option>
            </Select>
          </div>
        </div>
      </div>
      <Button type="primary" shape="circle" icon={<PlayCircleOutlined />} size="large" style={{ fontSize: '48px', backgroundColor: '#7D4CDB', borderColor: '#7D4CDB' }} />
      <div className="text-center mt-2 text-white">
        <Button type="link" className="text-white" style={{ fontSize: '1.25rem' }}>Start Sequence</Button>
      </div>
    </div>
  );
};

export default NextStep;
