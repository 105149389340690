import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  message,
  Select,
  Switch,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../AuthContext";
import { useParams } from "react-router-dom";
import {
  createMember,
  deleteMember,
  fetchMembersByOrgId,
} from "../../api/firebase/orgMemberApi";
import { createUser, updateUser } from "../../api/userApi";
import { fetchPermissions } from "../../api/firebase/permissionApi";
import { getAllRoles } from "../../api/firebase/roleApi";
import GlobalDeleteModal from "../../components/GlobalDeleteModal";
import GlobalModal from "../../components/GlobalModal";

const { Option } = Select;

const OrgMemberManagement = () => {
  const [members, setMembers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [editMember, setEditMember] = useState(null);
  const [deleteMemberId, setDeleteMemberId] = useState(null);
  const [memberForm] = Form.useForm();
  const { user } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    loadMembers();
    loadPermissions();
    loadRoles();
  }, []);

  const loadMembers = async () => {
    setLoading(true);
    try {
      const members = await fetchMembersByOrgId(id);
      setMembers(members);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch members");
      setLoading(false);
    }
  };

  const loadPermissions = async () => {
    setLoading(true);
    try {
      const permissions = await fetchPermissions();
      setPermissions(permissions);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch permissions");
      setLoading(false);
    }
  };

  const loadRoles = async () => {
    setLoading(true);
    try {
      const roles = await getAllRoles();
      setRoles(roles);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch roles");
      setLoading(false);
    }
  };

  const handleMemberSubmit = async (values) => {
    setLoading(true);
    try {
      const selectedPermissions = permissions.filter((permission) =>
        values.permissions.includes(permission.name)
      );

      if (editMember) {
        await updateUser(editMember.id, {
          email: values.email,
          name: values.name,
          invitedBy: user.uid,
          roleId: values.role,
          permissions: selectedPermissions,
          enabled: values.enabled || false,
        });
        setEditMember(null);
        message.success("Member updated successfully");
      } else {
        await createUser({
          email: values.email,
          name: values.name,
          roleId: values.role,
          permissions: selectedPermissions,
          enabled: values.enabled || false,
          orgId: id,
          verified:true,
          isPasswordUpdated:false,
          invitedBy: user.uid,
          password: values.password,
        });

        setEditMember(null);

        message.success("Member created successfully");
      }

      setIsMemberModalVisible(false);
      loadMembers();
    } catch (error) {
      console.error("Error during member creation:", error);
      message.error("Failed to save member");
      setLoading(false);
    }
  };

  const handleDeleteMember = async (memberId) => {
    setLoading(true);
    try {
      await deleteMember(memberId);
      message.success("Member deleted successfully");
      loadMembers();
    } catch (error) {
      message.error("Failed to delete member");
      setLoading(false);
    }
  };

  const openMemberModal = (member) => {
    setEditMember(member);
    if (member) {
      memberForm.setFieldsValue({
        ...member,
        permissions: member?.permissions?.map((perm) => perm.name),
      });
    } else {
      memberForm.resetFields();
    }
    setIsMemberModalVisible(true);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center">
      <Card className="w-full p-8 mb-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">
            Organization Member Management
          </h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openMemberModal(null)}
          >
            Create Member
          </Button>
        </div>
        <Table
          dataSource={members}
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            { title: "Email", dataIndex: "email", key: "email" },
            { title: "Role", dataIndex: "roleId", key: "roleId" },
            {
              title: "Enabled",
              dataIndex: "enabled",
              key: "enabled",
              render: (enabled) => (enabled ? "Yes" : "No"),
            },
            {
              title: "Actions",
              key: "actions",
              render: (text, record) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => openMemberModal(record)}
                  >
                    Edit
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setDeleteMemberId(record.id);
                      setIsDeleteModalVisible(true);
                    }}
                    danger
                  >
                    Delete
                  </Button>
                </Space>
              ),
            },
          ]}
          loading={loading}
          rowKey="id"
        />
      </Card>
      <GlobalModal
        visible={isMemberModalVisible}
        onOk={memberForm.submit}
        onCancel={() => {
          setIsMemberModalVisible(false);
          setEditMember(null);
          memberForm.resetFields();
        }}
        loading={loading}
        requiredPermission={
          editMember ? "canEditMembers" : "canCreateMembers"
        }
      >
        <Form
          form={memberForm}
          layout="vertical"
          onFinish={handleMemberSubmit}
          initialValues={editMember}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Please enter the member name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please enter the email" }]}
          >
            <Input />
          </Form.Item>
          {!editMember && (
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please enter the password" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please select a role" }]}
          >
            <Select placeholder="Select role">
              {roles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permissions"
            label="Permissions"
          >
            <Select mode="multiple" placeholder="Select permissions">
              {permissions.map((permission) => (
                <Option key={permission.name} value={permission.name}>
                  {permission.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="enabled" label="Enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </GlobalModal>
      <GlobalDeleteModal
        visible={isDeleteModalVisible}
        onOk={() => handleDeleteMember(deleteMemberId)}
        onCancel={() => setIsDeleteModalVisible(false)}
        loading={loading}
        requiredPermission="canDeleteMembers"
      />
    </div>
  );
};

export default OrgMemberManagement;
