import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Card,
  Typography,
  Space,
  message,
  Drawer,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { createAction, fetchActions } from "../../api/actionApi";
import { useAuth } from "../../AuthContext";

const { Title } = Typography;
const { Option } = Select;


const CreateActionPage = ({ fetchUpdatedActions }) => {
  const [form] = Form.useForm();
  const [actionType, setActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const [actions, setActions] = useState([]);
  const [show, setShow] = useState(false);
  const{serviceConfig} = useAuth()
  const service = serviceConfig?.service


  const handleTypeChange = (value) => {
    setActionType(value);
  };

  const handleCreateAction = async (values) => {
    if (values.name?.includes(" ")) {
      message.error(
        "Name should be like this. do not give space. (ex: transferToDavid)"
      );
      return;
    }
    try {
      setLoading(true);
      let actionData;
      if (values.type === "action_transfer_call") {
        if (service === "vapi") {
          // For "vapi" service

          // [
          //   {
          //     type: "endCall",
          //     function: {
          //       name: values.name,
          //       description: values.description,
          //       parameters: {
          //         type: "object",
          //         properties: {},
          //       },
          //       server: {
          //         url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/post-call-processing`,
          //       },
          //     },
          //   },
          // ]

          actionData = [
            {
              type: "transferCall",
              destinations: [
                {
                  type: "phoneNumber",
                  number: "+" + values.phoneNumber,
                  message: values.description,
                },
              ],
              function: {
                name: values?.name,
                description:
                  "Use this function to transfer the call. Only use it when following instructions that explicitly ask you to use the transferCall function. DO NOT call this function unless you are instructed to do so.",
                parameters: {
                  type: "object",
                  properties: {
                    destination: {
                      type: "string",
                      enum: ["+" + values.phoneNumber],
                      description: values.description,
                    },
                  },
                  required: ["destination"],
                },
              },
            },
          ];
        } else {
          actionData.config.phoneNumber = values.phoneNumber;
        }
      } else if (values.type === "action_external") {
        if (service === "vapi") {
          // For "vapi" service

          actionData = [
            {
              type: "function",
              function: {
                name: values.name,
                async: false,
                parameters: {
                  type: "object",
                  properties: values.inputSchema.reduce((acc, curr) => {
                    acc[curr.name] = { type: curr.type };
                    return acc;
                  }, {}),
                },
              },
              server: {
                url: `${process.env.REACT_APP_SERVER_URL}/calls/vapi/during-call-processing`,
              },
            },
          ];
        } else {
          // For other services (like vocode)
          actionData = {
            type: values.type,
            config: {
              speakOnSend: true,
              speakOnReceive: true,
            },
            actionTrigger: {
              type: "action_trigger_function_call",
              config: {},
            },
          };

          actionData.config.description = values.description;
          actionData.config.name = values.name;
          actionData.config.url =
            process.env.REACT_APP_SERVER_URL + "/leads/save-user-details";
          actionData.config.inputSchema = {
            type: "object",
            properties: values.inputSchema.reduce((acc, curr) => {
              acc[curr.name] = { type: curr.type };
              return acc;
            }, {}),
          };
        }
      }

      const res = await createAction({ ...actionData, orgId: userData?.orgId });
      setLoading(false);
      message.success("Action created successfully!");
      if (fetchUpdatedActions) {
        fetchUpdatedActions(res);
      }
    } catch (error) {
      console.error("Error creating action:", error);
      setLoading(false);
      message.error("Failed to create action.");
    }
  };

  const getAllAction = async () => {
    const actionsData = await fetchActions(1, 30, userData?.orgId);
    setActions(actionsData?.items);
  };
  useEffect(() => {
    getAllAction();
  }, []);

  return (
    <div className="p-8">
      <div className="flex items-center justify-between">
        <Title level={2}>Create New Action</Title>
        {actions && actions?.length > 0 && (
          <Button onClick={() => setShow(!show)} className="" size="large">
            {show ? "Hide" : "See"} Actions
          </Button>
        )}
      </div>

      <Card className="bg-white p-4 rounded-lg">
        <Form form={form} layout="vertical" onFinish={handleCreateAction}>
          <Form.Item
            name="type"
            label="Type"
            rules={[
              { required: true, message: "Please select the action type" },
            ]}
          >
            <Select
              placeholder="Select action type"
              onChange={handleTypeChange}
            >
              <Option value="action_transfer_call">Call redirection</Option>
              <Option value="action_external">Collect user data</Option>
            </Select>
          </Form.Item>

          {actionType === "action_transfer_call" && (
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the phone number (ex: Country Code + Phone Number)",
                },
              ]}
            >
              <Input placeholder="Enter phone number (ex: Country Code + Phone Number)" />
            </Form.Item>
          )}

          <Form.Item
            name="name"
            label="Action Name"
            rules={[{ required: true, message: "Please enter action name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please enter description" }]}
            label={
              service === "vapi" && actionType === "action_transfer_call"
                ? "Wo text jo call redirect per bolna hain"
                : "Action Description (Optional reference text explaining the purpose and functionality of this action)"
            }
          >
            <Input
              placeholder={
                service === "vapi" && actionType === "action_transfer_call"
                  ? "Ex: I am forwarding your call to Department A. Please stay on the line."
                  : ""
              }
            />
          </Form.Item>

          {actionType === "action_external" && (
            <>
              <Form.List name="inputSchema">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the field name",
                            },
                          ]}
                        >
                          <Input placeholder="Key Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "type"]}
                          fieldKey={[fieldKey, "type"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select the field type",
                            },
                          ]}
                        >
                          <Select placeholder="Field Type">
                            <Option value="string">String</Option>
                            <Option value="number">Number</Option>
                            <Option value="boolean">Boolean</Option>
                            <Option value="object">Object</Option>
                            <Option value="array">Array</Option>
                          </Select>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )}

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Create Action
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {actions && actions?.length > 0 && (
        <>
          <>
            <Drawer
              title="See All Actions Here"
              visible={show}
              onClose={() => setShow(false)}
            >
              {actions?.map((action) => (
                <Card key={action?.id} className="mb-2">
                  <Typography.Text strong>
                    {action?.type === "action_transfer_call"
                      ? `${action?.config?.phoneNumber}`
                      : action?.config?.name}
                  </Typography.Text>
                  {action?.type === "action_external" && (
                    <div>
                      <Typography.Title level={5}>
                        Input Schema
                      </Typography.Title>
                      {action?.config.inputSchema?.properties &&
                        Object.entries(
                          action?.config.inputSchema.properties
                        ).map(([name, schema]) => (
                          <div key={name}>
                            <Typography.Text>
                              {name} ({schema.type})
                            </Typography.Text>
                          </div>
                        ))}
                    </div>
                  )}

                  {action?.service === "vapi" && (
                    <div>
                      <Typography.Title level={5}>
                        {action?.function?.name}
                      </Typography.Title>

                      {action?.function.parameters?.properties &&
                        Object.entries(
                          action?.function.parameters?.properties
                        ).map(([name, schema]) => (
                          <div key={name}>
                            <Typography.Text>
                              {name} ({schema.type})
                            </Typography.Text>
                          </div>
                        ))}
                    </div>
                  )}

                  <Button
                    type="primary"
                    className="w-full mt-2"
                    onClick={() => {
                      fetchUpdatedActions && fetchUpdatedActions(action);
                    }}
                  >
                    Add
                  </Button>
                </Card>
              ))}
            </Drawer>
          </>
        </>
      )}
    </div>
  );
};

export default CreateActionPage;
