import React, { useState } from 'react';
import { Input, Button, Radio, Switch, Typography } from 'antd';
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;
const { Title, Text } = Typography;

const CreateSequence = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [availability, setAvailability] = useState('everyone');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSave = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Process name is invalid';
    if (!description) newErrors.description = 'Process description is invalid';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Handle save logic here
      console.log('Saving sequence:', { name, description, availability });
      navigate('/next-step');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center cursor-pointer text-xl font-medium">
            <LeftOutlined className="mr-2" /> Processes
          </div>
          <div className="flex items-center space-x-4">
            <div className="text-gray-500">Last updated a few seconds ago</div>
            <div className="text-gray-500">...</div>
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            <Button>Test sequence</Button>
            <Button
              type="primary"
              onClick={handleSave}
              disabled={!name || !description}
              style={{ backgroundColor: '#7D4CDB', borderColor: '#7D4CDB' }}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="bg-white border rounded-md p-8 mb-8">
          <div className="flex items-center mb-4">
            <InfoCircleOutlined style={{ fontSize: '24px' }} className="mr-2" />
            <Title level={4} className="m-0">Identify this Sequence</Title>
          </div>
          <Text>Describe what this Sequence will do and when it should be used.</Text>
          <div className="mt-4">
            <div className="mb-4">
              <Text className="block mb-2">Name</Text>
              <Input
                placeholder="Enter a name for this Sequence"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mb-1"
              />
              {errors.name && <Text type="danger">{errors.name}</Text>}
            </div>
            <div className="mb-4">
              <Text className="block mb-2">Describe when to use this Sequence</Text>
              <TextArea
                rows={4}
                placeholder="Describe when to use this Sequence, such as 'Respond to questions about the privacy policy'"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mb-1"
              />
              {errors.description && <Text type="danger">{errors.description}</Text>}
            </div>
            <div className="mb-4">
              <Text className="block mb-2">Set availability</Text>
              <Radio.Group value={availability} onChange={(e) => setAvailability(e.target.value)}>
                <Radio value="everyone">Everyone</Radio>
                <Radio value="rules">Based on the following rules...</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="bg-white border rounded-md p-8">
          <div className="flex items-center mb-4">
            <div style={{ fontSize: '24px' }} className="mr-2">&#128221;</div>
            <Title level={4} className="m-0">Build the Sequence</Title>
          </div>
          <Text>Create the steps the AI agent will take when following this Sequence.</Text>
          <div className="mt-8 border-dashed border-2 border-gray-300 p-16 text-gray-500">
            Start building by dragging in blocks
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSequence;
