import React from 'react'
import CallPage from './CallPage'

export default function OutGoingCalls() {
  return (
    <div>
        <CallPage title="Outgoing Calls" type="outboundPhoneCall"/>
    </div>
  )
}
