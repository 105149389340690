// import React, { useState, useEffect } from 'react';
// import { Form, Input, Select, Button, Checkbox, Card, Typography, message, Spin } from 'antd';
// import { auth, firestore } from '../../firebaseConfig';
// import { createUserWithEmailAndPassword, updatePassword } from 'firebase/auth';
// import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
// import { useNavigate, useParams } from 'react-router-dom';

// const { Title } = Typography;

// const permissions = [
//   'View Dashboard',
//   'Edit Profile',
//   'Manage Users',
//   'View Reports',
//   'Create Content',
//   'Edit Content',
//   'Delete Content'
// ];

// const InviteUserPage = ({ mode = 'create' }) => {
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [form] = Form.useForm();
//   const { userId } = useParams();

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (mode === 'edit' && userId) {
//       fetchUserData(userId);
//     }
//   }, [mode, userId]);

//   const fetchUserData = async (userId) => {
//     setLoading(true);
//     try {
//       const userDoc = await getDoc(doc(firestore, 'users', userId));
//       if (userDoc.exists()) {
//         const userData = userDoc.data();
//         form.setFieldsValue({
//           email: userData.email,
//           name: userData.name,
//           role: userData.role,
//           permissions: userData.permissions,
//           phoneNumber: userData.phoneNumber,
//         });
//         setSelectedPermissions(userData.permissions || []);
//       } else {
//         message.error('User not found');
//       }
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//       message.error('An error occurred while fetching the user data');
//       setLoading(false);
//     }
//   };

//   const handlePermissionChange = (checkedValues) => {
//     setSelectedPermissions(checkedValues);
//   };

//   const handleSubmit = async (values) => {
//     setLoading(true);
//     try {
//       if (mode === 'create') {
//         // Create user with Firebase Auth
//         const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
//         const user = userCredential.user;

//         // Store user details and permissions in Firestore
//         await setDoc(doc(firestore, 'users', user.uid), {
//           email: values.email,
//           name: values.name,
//           role: values.role,
//           permissions: selectedPermissions,
//           phone: values.phoneNumber,
//           verified: false, // Mark the account as unverified
//           createdAt: new Date()
//         });

//         message.success('User created successfully!');
//       } else {
//         // Update user details and permissions in Firestore
//         await updateDoc(doc(firestore, 'users', userId), {
//           role: values.role,
//           permissions: selectedPermissions,
//           phoneNumber: values.phoneNumber,
//         });

//         if (values.password) {
//           const user = auth.currentUser;
//           await updatePassword(user, values.password);
//         }

//         message.success('User updated successfully!');
//       }
//       navigate('/users');
//     } catch (error) {
//       console.error('Error saving user:', error);
//       message.error(`An error occurred while ${mode === 'create' ? 'creating' : 'updating'} the user`);
//     }
//     setLoading(false);
//   };

//   return (
//     <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
//       <Card className="w-full max-w-3xl p-8">
//         <Title level={2}>{mode === 'create' ? 'Add User' : 'Edit User'}</Title>
//         {loading ? (
//           <div className="flex justify-center items-center min-h-[200px]">
//             <Spin size="large" />
//           </div>
//         ) : (
//           <Form form={form} layout="vertical" onFinish={handleSubmit}>
//             <Form.Item
//               name="name"
//               label="Name"
//               rules={[{ required: true, message: 'Please enter the name' }]}
//             >
//               <Input disabled={mode === 'edit'} />
//             </Form.Item>
//             <Form.Item
//               name="email"
//               label="Email Address"
//               rules={[{ required: true, message: 'Please enter the email address' }]}
//             >
//               <Input disabled={mode === 'edit'} />
//             </Form.Item>
//             <Form.Item
//               name="role"
//               label="Role"
//               rules={[{ required: true, message: 'Please select the role' }]}
//             >
//               <Select placeholder="Select a role">
//                 <Select.Option value="admin">Admin</Select.Option>
//                 <Select.Option value="administrator">Administrator</Select.Option>
//                 <Select.Option value="manager">Manager</Select.Option>
//                 <Select.Option value="user">User</Select.Option>
//               </Select>
//             </Form.Item>
//             <Form.Item
//               name="password"
//               label="Password"
//               rules={[{ required: mode === 'create', message: 'Please enter the password' }]}
//             >
//               <Input.Password />
//             </Form.Item>
//             <Form.Item
//               name="phoneNumber"
//               label="Phone Number"
//             >
//               <Input />
//             </Form.Item>
//             <Form.Item
//               name="permissions"
//               label="Permissions"
//             >
//               <Checkbox.Group onChange={handlePermissionChange} value={selectedPermissions}>
//                 {permissions.map(permission => (
//                   <Checkbox key={permission} value={permission}>{permission}</Checkbox>
//                 ))}
//               </Checkbox.Group>
//             </Form.Item>
//             <Form.Item>
//               <Button type="primary" htmlType="submit">
//                 {mode === 'create' ? 'Create User' : 'Save Changes'}
//               </Button>
//             </Form.Item>
//           </Form>
//         )}
//       </Card>
//     </div>
//   );
// };

// export default InviteUserPage;


import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Checkbox, Card, Typography, message, Spin } from 'antd';
import { auth, firestore } from '../../firebaseConfig';
import { createUserWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext'; // Make sure you import useAuth from your AuthContext

const { Title } = Typography;

const permissions = [
  'View Dashboard',
  'Edit Profile',
  'Manage Users',
  'View Reports',
  'Create Content',
  'Edit Content',
  'Delete Content'
];

const InviteUserPage = ({ mode = 'create' }) => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { userId } = useParams();
  const { user: loggedInUser, userData: loggedInUserData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (mode === 'edit' && userId) {
      fetchUserData(userId);
    }
  }, [mode, userId]);

  const fetchUserData = async (userId) => {
    setLoading(true);
    try {
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        form.setFieldsValue({
          email: userData.email,
          name: userData.name,
          role: userData.role,
          permissions: userData.permissions,
          phoneNumber: userData.phoneNumber,
          credits: userData.credits,
        });
        setSelectedPermissions(userData.permissions || []);
      } else {
        message.error('User not found');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      message.error('An error occurred while fetching the user data');
      setLoading(false);
    }
  };

  const handlePermissionChange = (checkedValues) => {
    setSelectedPermissions(checkedValues);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (mode === 'create') {
        // Create user with Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
        const user = userCredential.user;

        // Determine organization for the new user
        let organization = null;
        if (values.role !== 'admin' && loggedInUserData.role === 'admin') {
          organization = loggedInUser.uid;
        } else if (values.role === 'admin') {
          organization = user.uid;
        }

        // Store user details and permissions in Firestore
        await setDoc(doc(firestore, 'users', user.uid), {
          email: values.email,
          name: values.name,
          role: values.role,
          permissions: selectedPermissions,
          phoneNumber: values.phoneNumber,
          credits: values.credits,
          verified: false, // Mark the account as unverified
          organization,
          createdAt: new Date()
        });

        message.success('User created successfully!');
      } else {
        // Update user details and permissions in Firestore
        await updateDoc(doc(firestore, 'users', userId), {
          role: values.role,
          permissions: selectedPermissions,
          phoneNumber: values.phoneNumber,
          credits: values.credits,
        });

        if (values.password) {
          const user = auth.currentUser;
          await updatePassword(user, values.password);
        }

        message.success('User updated successfully!');
      }
      navigate('/users');
    } catch (error) {
      console.error('Error saving user:', error);
      message.error(`An error occurred while ${mode === 'create' ? 'creating' : 'updating'} the user`);
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
      <Card className="w-full max-w-3xl p-8">
        <Title level={2}>{mode === 'create' ? 'Add User' : 'Edit User'}</Title>
        {loading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <Spin size="large" />
          </div>
        ) : (
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ required: true, message: 'Please enter the email address' }]}
            >
              <Input disabled={mode === 'edit'} />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Please select the role' }]}
            >
              <Select placeholder="Select a role">
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="administrator">Administrator</Select.Option>
                <Select.Option value="manager">Manager</Select.Option>
                <Select.Option value="user">User</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: mode === 'create', message: 'Please enter the password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="credits"
              label="Credits"
              rules={[{ required: true, message: 'Please enter the number of credits' }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name="permissions"
              label="Permissions"
            >
              <Checkbox.Group onChange={handlePermissionChange} value={selectedPermissions}>
                {permissions.map(permission => (
                  <Checkbox key={permission} value={permission}>{permission}</Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {mode === 'create' ? 'Create User' : 'Save Changes'}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default InviteUserPage;
