import { firestore } from "../../firebaseConfig";
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, query, where } from "firebase/firestore";
const featuresCollection = collection(firestore, 'features');

export const createFeature = async (feature) => {
  const featuresCollection = collection(firestore, "features");
  const docRef = await addDoc(featuresCollection, feature);
  return { id: docRef.id, ...feature };
};

export const updateFeature = async (id, feature) => {
  const featuresCollection = collection(firestore, "features");
  const featureDoc = doc(featuresCollection, id);
  await updateDoc(featureDoc, feature);
};

export const deleteFeature = async (id) => {
  const featuresCollection = collection(firestore, "features");
  const featureDoc = doc(featuresCollection, id);
  await deleteDoc(featureDoc);
};

export const fetchFeatures = async (planId) => {
  const featuresCollection = collection(firestore, "features");
  const q = query(featuresCollection, where("planId", "==", planId));
  const featuresSnapshot = await getDocs(q);
  return featuresSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};


export const fetchFeaturesByIds = async (featureIds) => {
    const snapshot = await getDocs(featuresCollection);
    const features = snapshot.docs
      .filter(doc => featureIds.includes(doc.id))
      .map((doc) => ({ id: doc.id, ...doc.data() }));
    return features;
  };