import axios from "axios";

const apiKey = process.env.REACT_APP_ELEVANLABS_KEY;
const elevanLabsApi = "https://api.elevenlabs.io/v1";

export const fetchVoices = async () => {
  const response = await axios.get(`${elevanLabsApi}/voices`, {
    headers: {
      "xi-api-key": apiKey,
    },
  });
  return response.data?.voices;
};

export const fetchVoiceById = async (voiceId) => {
  const response = await axios.get(`${elevanLabsApi}/voices/${voiceId}`, {
    headers: {
      "xi-api-key": apiKey,
    },
  });
  return response;
};

export const textToVoiceFetch = async (voiceId, text) => {
  const response = await axios.post(
    `${elevanLabsApi}/text-to-speech/${voiceId}`,
    { text: text },
    {
      headers: {
        "xi-api-key": apiKey,
      },
    }
  );
  return response;
};
