// src/orm/firestoreOrm.js
import {
    collection,
    doc,
    getDoc,
    getDocs,
    addDoc,
    setDoc,
    updateDoc,
    deleteDoc,
    query,
    where,
    Timestamp,
  } from 'firebase/firestore';
  import { firestore } from '../firebaseConfig';
  
  class FirestoreORM {
    constructor(collectionName) {
      this.collectionRef = collection(firestore, collectionName);
    }
  
    async create(data) {
      data.createdAt = Timestamp.now();
      const docRef = await addDoc(this.collectionRef, data);
      return docRef.id;
    }
  
    async read(id) {
      const docRef = doc(this.collectionRef, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        throw new Error('Document not found');
      }
    }
  
    async update(id, data) {
      data.updatedAt = Timestamp.now();
      const docRef = doc(this.collectionRef, id);
      await updateDoc(docRef, data);
    }
  
    async delete(id) {
      const docRef = doc(this.collectionRef, id);
      await deleteDoc(docRef);
    }
  
    async findAll() {
      const querySnapshot = await getDocs(this.collectionRef);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    }
  
    async findByField(fieldName, value) {
      const q = query(this.collectionRef, where(fieldName, '==', value));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    }
  }
  
  export default FirestoreORM;
  