// src/pages/VerifyAccountPage.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Typography, Form, Input, message, Spin } from 'antd';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';

const { Title, Paragraph } = Typography;

const VerifyAccountPage = () => {
  const { user, userData, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (user && userData && userData?.verified) {
      navigate('/');
    }
  }, [user, userData, navigate]);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handlePasswordChange = async (values) => {
    setLoading(true);
    try {
      const credential = EmailAuthProvider.credential(user.email, values.currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Update the user's password
      await updatePassword(user, values.password);

      // Update the user's verification status in Firestore
      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, { verified: true,isPasswordUpdated:true });

      message.success('Password changed and account verified successfully!');
      window.location.reload()
      
    } catch (error) {
      console.error('Error updating password or verifying account:', error);
      if (error.code === 'auth/wrong-password') {
        message.error('The current password is incorrect.');
      } else {
        message.error('An error occurred while updating the password or verifying the account.');
      }
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
      <Card className="w-full max-w-3xl p-8">
        <Title level={2}>Account Verification Required</Title>
        <Paragraph>
          Your account has not been verified yet. Please change your password to verify your account.
        </Paragraph>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form form={form} layout="vertical" onFinish={handlePasswordChange}>
            <Form.Item
              name="currentPassword"
              label="Current Password"
              rules={[{ required: true, message: 'Please enter your current password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="password"
              label="New Password"
              rules={[{ required: true, message: 'Please enter your new password' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your new password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Change Password & Verify
              </Button>
            </Form.Item>
          </Form>
        )}
        <Button type="default" onClick={handleLogout}>
          Logout
        </Button>
      </Card>
    </div>
  );
};

export default VerifyAccountPage;
