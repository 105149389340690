import axios from 'axios';
import apiClient from '../api/index';

export const startTimer = async (organizationId, duration) => {
  try {
    const response = await apiClient.post(`/organizations/start-timer`, {
      organizationId,
      duration,
    });
    return response.data;
  } catch (error) {
    console.error('Error starting timer:', error);
    throw error;
  }
};
