import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Row, Col, Spin, message, Breadcrumb, Divider } from "antd";
import { fetchPlans, fetchPlansByOrgId } from "../../api/firebase/planApi";
import {
  fetchFeatures,
  fetchFeaturesByIds,
} from "../../api/firebase/featureApi";
import { checkoutSession } from "../../utils/helper";
import { useAuth } from "../../AuthContext";
import PricingPlans from "../../components/PricingPlans";

const { Title, Paragraph, Text } = Typography;

const PricingPage = () => {
  const { userData, organization } = useAuth();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);

  useEffect(() => {
    const loadPlansAndFeatures = async () => {
      try {
        const fetchedPlans = await fetchPlansByOrgId(userData?.orgId);
        for (let plan of fetchedPlans) {
          const features = await fetchFeatures(plan.id);
          plan.features = features.map((feature) => feature.name);
        }
        setPlans(fetchedPlans);
      } catch (error) {
        message.error("Failed to fetch plans and features");
      } finally {
        setLoading(false);
      }
    };

    loadPlansAndFeatures();
  }, []);

  return (
    <div className=" bg-white w-full h-screen">
      <div className="flex items-center justify-between px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Transparent pricing",
            },
          ]}
        />

      </div>

      <Divider />

      {loading ? (
        <div className="text-center">
          <Spin size="large" />
        </div>
      ) : (
        <PricingPlans plans={plans} />
      )}
    </div>
  );
};

export default PricingPage;
