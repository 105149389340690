import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Card, Divider, Typography, Modal } from 'antd';
import { auth } from '../firebaseConfig';
import { updatePassword, signOut, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const Settings = () => {
  const [apiKey, setApiKey] = useState('');
  const [form] = Form.useForm();
  const [reauthForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reauthVisible, setReauthVisible] = useState(false);
  const [passwordValues, setPasswordValues] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const storedApiKey = localStorage.getItem('voCodeToken');
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem('voCodeToken', apiKey);
    message.success('API key saved successfully!');
  };

  const handlePasswordChange = async (values) => {
    setPasswordValues(values);
    setReauthVisible(true);
  };

  const handleReauthSubmit = async (values) => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, values.password);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwordValues.password);
      message.success('Password changed successfully!');
      form.resetFields();
      setReauthVisible(false);
    } catch (error) {
      console.error('Error re-authenticating:', error);
      message.error('Failed to re-authenticate. Please try again.');
    }
    setLoading(false);
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center space-y-8">
      {/* API Key Section */}
      {/* <Card title="API Key" className="w-full max-w-md" bordered={false}>
        <Title level={4} className="text-center text-blue-500 mb-4">Vo-Code API Key</Title>
        <Form layout="vertical" onFinish={handleSave}>
          <Form.Item label="API Key" required>
            <Input
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Enter your API key"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#7D4CDB', borderColor: '#7D4CDB' }}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card> */}

      {/* Change Password Section */}
      <Card title="Change Password" className="w-full max-w-md" bordered={false}>
        <Title level={4} className="text-center text-blue-500 mb-4">Change Password</Title>
        <Form layout="vertical" form={form} onFinish={handlePasswordChange}>
          <Form.Item
            name="password"
            label="New Password"
            rules={[{ required: true, message: 'Please enter your new password' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your new password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#7D4CDB', borderColor: '#7D4CDB' }}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {/* Logout Button */}
      <Card className="w-full max-w-md" bordered={false}>
        <Button type="default" onClick={handleLogout} block style={{ backgroundColor: '#FF4D4F', borderColor: '#FF4D4F', color: '#fff' }}>
          Logout
        </Button>
      </Card>

      {/* Re-authentication Modal */}
      <Modal
        visible={reauthVisible}
        title="Re-authenticate"
        onCancel={() => setReauthVisible(false)}
        footer={null}
      >
        <Form form={reauthForm} onFinish={handleReauthSubmit}>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Re-authenticate
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Settings;
