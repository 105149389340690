import apiClient from './index';

export const stripePaymentCreate = async (data) => {
  const response = await apiClient.post(`/payments/stripe/create-payment-intent`,data);
  return response.data;
};

export const stripeCreateCheckoutSession = async (data) => {
  const response = await apiClient.post(`/payments/stripe/create-checkout-session`,data);
  return response.data;
};