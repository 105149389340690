import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Form, Input, message, Typography } from "antd";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { createUser } from "../../api/firebase/userApi";
import { createOrganization } from "../../api/firebase/organizationApi";
import { getAllRoles } from "../../api/firebase/roleApi";
import { useAuth } from "../../AuthContext";
import { fetchFreePlan, fetchPlans } from "../../api/firebase/planApi";
import avatarRegister from "../../assets/avatarRegister.png";
import MailLine from "../../assets/mail-line.png";
import lockLine from "../../assets/lockLine.png";
import info from "../../assets/info.png";
import Label from "../../components/Label";

const Signup = () => {
  const { setSignupInProgress } = useAuth();
  const [loading, setLoading] = useState(false);
  const [adminDetails, setAdminDetails] = useState("");
  const [planId, setPlanId] = useState("");
  useEffect(() => {
    fetchRoles();
    fetchPlansInLocal();
  }, []);

  const fetchPlansInLocal = async () => {
    try {
      const plans = await fetchFreePlan();

      if (plans && plans.length > 0) {
        setPlanId(plans[0].id);
      }

      // const freePlanData = plans?.find((item) => item?.price == 0);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const fetchRoles = async () => {
    try {
      const roles = await getAllRoles();
      const admin = roles?.find((item) =>
        item?.name?.toLowerCase()?.includes("admin")
      );
      setAdminDetails(admin);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const handleSignup = async (values) => {
    if (!planId) {
      message.error("We don't have plan right now for you.");
      return;
    }

    setLoading(true);
    setSignupInProgress(true);
    try {
      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      const user = userCredential.user;

      // Create organization
      const organizationId = await createOrganization({
        name: values.organizationName,
        ownerId: user.uid,
        createdAt: new Date(),
        totalAllocatedMinutes: 0,
        usedMinutes: 0,
        freePlanMinutes: 30,
        planId,
      });

      // Create user in Firestore
      await createUser({
        id: user.uid,
        email: values.email,
        name: values.name,
        roleId: "admin",
        orgId: organizationId,
        verified: false,
        permissions: [],
        enabled: true,
      });

      // // Create member in Firestore
      // await createMember({
      //   id: user.uid,
      //   email: values.email,
      //   name: values.name,
      //   roleId: adminDetails?.id || "",
      //   orgId: organizationId,
      //   verified: true,
      //   createdAt: new Date(),
      //   password: values.password,
      // });

      message.success("Signup successful");

      // Login user
      await signInWithEmailAndPassword(auth, values.email, values.password);
      setSignupInProgress(false);

      window.location.href = "/organizations"; // Navigate to the desired route after successful signup
    } catch (error) {
      console.error("Error during signup:", error);
      message.error("Signup failed. Please try again.");
      setSignupInProgress(false);
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center  bg-white h-full w-full">
      <div
        className="w-full max-w-sm p-6 rounded-lg"
        style={{
          border: "1px solid #E1E4EA",
        }}
      >
        <div className="flex flex-col items-center justify-between w-full">
          <img className="w-14 h-14 block" src={avatarRegister} />
          <Typography.Text className="block text-2xl font-medium mt-2">
            Create a new account
          </Typography.Text>
        </div>
        <Divider />
        <Form requiredMark={false} layout="vertical" onFinish={handleSignup}>
          <Form.Item
            name="name"
            label={<Label required>Your name</Label>}
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input
              prefix={<img className="w-4 h-4 block" src={lockLine} />}
              placeholder="Your name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={<Label required>Company email</Label>}
            rules={[{ required: true, message: "Please enter your email" }]}
          >
            <Input
              placeholder="hello@alignui.com"
              prefix={<img className="w-4 h-4 block" src={MailLine} />}
            />
          </Form.Item>
          <Form.Item
            name="organizationName"
            label={<Label required>Organization Name</Label>}
            rules={[
              {
                required: true,
                message: "Please enter your organization name",
              },
            ]}
          >
            <Input
              prefix={<img className="w-4 h-4 block" src={lockLine} />}
              placeholder="Your organization name"
            />
          </Form.Item>
          <div>
            <Form.Item
              name="password"
              label={<Label required>Password</Label>}
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password
                placeholder="• • • • • • • • • • "
                prefix={<img className="w-4 h-4 block" src={lockLine} />}
              />
            </Form.Item>
            <div className="flex items-center mt-[-20px] mb-2">
              <img src={info} className="w-3 h-3" />
              <Typography.Text className="block ml-1 text-[10px] text-[#525866] font-light">
                Must contain 1 uppercase letter, 1 number, min. 8 characters.
              </Typography.Text>
            </div>
          </div>
          {/* <Form.Item
            name="confirmPassword"
            label={<Label>Confirm Password</Label>}
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item> */}
          <Form.Item>
            <Button
              className="bg-black text-white rounded-lg "
              size="large"
              htmlType="submit"
              loading={loading}
              block
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <Typography.Text className="block text-center text-xs text-[#525866] mt-[-16px]" >
          By clicking Register, you agree to accept agentel's
        </Typography.Text>
      </div>
    </div>
  );
};

export default Signup;
