import React from "react";
import { Layout, Menu } from "antd";
import Sidebar from "./Sidebar";
import { useAuth } from "../AuthContext";
import LoginHeader from "./LoginHeader";
import FooterHeader from "./FooterHeader";

const { Header, Sider, Content } = Layout;

const AppLayout = ({ children }) => {
  const { user } = useAuth();
  return (
    <Layout style={{ height: "100vh", backgroundColor: "white" }}>
      {!user && <LoginHeader />}
      {user && (
        <Sider
          width={250}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <Sidebar />
        </Sider>
      )}
      <Layout style={{ marginLeft: user ? 250 : 0 }}>
        <Content style={{ overflowY: "auto", height: "calc(100vh - 64px)" }}>
          {children}
        </Content>
      </Layout>

      {!user && <FooterHeader />}
    </Layout>
  );
};

export default AppLayout;
