// src/api/roleApi.js
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import FirestoreORM from '../../orm/firestoreOrm';
import { firestore } from '../../firebaseConfig';

const rolesORM = new FirestoreORM('roles');
const roleollection = collection(firestore, 'roles');

export const createRole = async (data) => {
  const docRef = doc(roleollection, data.id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    throw new Error("Role with this ID already exists");
  }
  await setDoc(docRef, data);
  return docRef.id;
};

export const getRole = async (roleId) => {
  return await rolesORM.read(roleId);
};

export const updateRole = async (roleId, roleData) => {
  await rolesORM.update(roleId, roleData);
};

export const deleteRole = async (roleId) => {
  await rolesORM.delete(roleId);
};

export const getAllRoles = async () => {
  return await rolesORM.findAll();
};

export const getRolesByField = async (fieldName, value) => {
  return await rolesORM.findByField(fieldName, value);
};
