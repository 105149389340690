import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Spin, Typography } from "antd";

const CallsChart = ({ data, loading }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && data) {
      const ctx = chartRef.current.getContext("2d");
      const chartData = data.reduce((acc, call) => {
        const date = new Date(call.startTime).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = { duration: 0, count: 0 };
        }
        acc[date].duration += call.duration;
        acc[date].count += 1;
        return acc;
      }, {});

      const labels = Object.keys(chartData);
      const durations = labels.map((label) => chartData[label].duration);
      const counts = labels.map((label) => chartData[label].count);

      if (window.myChart) {
        window.myChart.destroy();
      }

      window.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Call Duration (minutes)",
              data: durations,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
              yAxisID: "y1",
            },
            {
              label: "Number of Calls",
              data: counts,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              borderColor: "rgba(153, 102, 255, 1)",
              borderWidth: 1,
              yAxisID: "y2",
            },
          ],
        },
        options: {
          scales: {
            y1: {
              beginAtZero: true,
              position: "left",
              title: {
                display: true,
                text: "Duration (minutes)",
              },
            },
            y2: {
              beginAtZero: true,
              position: "right",
              title: {
                display: true,
                text: "Number of Calls",
              },
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <div className="relative h-96">
      {loading ? (
        <div className="flex justify-center items-center w-full h-[300px]">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {data?.length === 0 ? (
            <Typography.Text
              type="secondary"
              className="block text-center w-full"
            >
              Not Enough Data
            </Typography.Text>
          ) : (
            <canvas ref={chartRef}></canvas>
          )}
        </>
      )}
    </div>
  );
};

export default CallsChart;
