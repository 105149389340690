// src/api/firebase/organizationApi.js
import { collection, addDoc, updateDoc, deleteDoc, getDoc, query, where, getDocs, doc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";

const ORGANIZATIONS_COLLECTION = "organizations";

export const createOrganization = async (data) => {
  const docRef = await addDoc(collection(firestore, ORGANIZATIONS_COLLECTION), data);
  return docRef.id;
};

export const updateOrganization = async (id, data) => {
  const docRef = doc(firestore, ORGANIZATIONS_COLLECTION, id);
  await updateDoc(docRef, data);
};

export const deleteOrganization = async (id) => {
  const docRef = doc(firestore, ORGANIZATIONS_COLLECTION, id);
  await deleteDoc(docRef);
};

export const fetchOrganizationsByUserId = async (userId) => {
  const q = query(collection(firestore, ORGANIZATIONS_COLLECTION), where("ownerId", "==", userId));
  const querySnapshot = await getDocs(q);
  const organizations = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return organizations;
};


export const fetchOrganizations = async () => {
  const organizationsSnapshot = await getDocs(ORGANIZATIONS_COLLECTION);
  return organizationsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};



export const fetchOrganizationById = async (orgId) => {
  const docRef = doc(firestore, ORGANIZATIONS_COLLECTION, orgId);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ...docSnapshot.data() };
  } else {
    throw new Error(`Organization with ID ${orgId} not found`);
  }
};