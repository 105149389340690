import React from "react";
import { Modal, Typography } from "antd";
import { useAuth } from "../AuthContext";
import "./GlobalModal.css";

const GlobalModal = ({
  visible,
  onOk,
  onCancel,
  loading,
  requiredPermission,
  children,
  title,
  noFooter,
  bodyStyle
}) => {
  const { plan, userData } = useAuth();

  // Function to check if the required permission exists in the permissions array
  const hasPermission = () => {
    return plan?.permissions?.some(
      (permission) => permission.name === requiredPermission
    );
  };

  const isAuthorized = userData.roleId === "owner" || hasPermission();

  return (
    <>
      {visible && <div className="blurred-background" />}
      <Modal
        bodyStyle={bodyStyle}
        footer={noFooter}
        title={isAuthorized ? title : "Not Authorized"}
        visible={visible}
        onOk={isAuthorized ? onOk : onCancel}
        onCancel={onCancel}
        confirmLoading={loading}
      >
        {isAuthorized ? (
          children
        ) : (
          <div className="blurred-content">
            <Typography.Text>
              You are not authorized to perform this action.
            </Typography.Text>
          </div>
        )}
      </Modal>
    </>
  );
};

export default GlobalModal;
