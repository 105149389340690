import axios from "axios";

const apiKey = process.env.REACT_APP_CARTESIA_KEY;
const cartesiaApi = "https://api.cartesia.ai";

export const cartesiaVoices = async () => {
  const response = await axios.get(`${cartesiaApi}/voices`, {
    headers: {
      "x-api-key": apiKey,
      "Cartesia-Version": "2024-06-10",
    },
  });

  return response.data;
};

export const streamSpeechCartesiaVoicePlay = async () => {
  const response = await axios.post(
    `${cartesiaApi}/tts/bytes`,
    {
      model_id: "sonic-english",
      transcript: "Hello, world! I'm generating audio on Cartesia.",
      duration: 123,
      voice: {
        mode: "id",
        id: "a0e99841-438c-4a64-b679-ae501e7d6091",
        __experimental_controls: {
          speed: "normal",
          emotion: ["positivity:high", "curiosity"],
        },
      },
      output_format: {
        container: "raw",
        encoding: "pcm_s16le",
        sample_rate: 8000,
      },
      language: "en",
    },
    {
      responseType: 'arraybuffer', 
      headers: {
        "x-api-key": apiKey,
        "Cartesia-Version": "2024-06-10",
      },
    }
  );

  return response.data;
};

export const fetchVoiceById = async (voiceId) => {
  const response = await axios.get(`${cartesiaApi}/voices/${voiceId}`, {
    headers: {
      "x-api-key": apiKey,
      "Cartesia-Version": "2024-06-10",
    },
  });
  return response;
};

export const textToVoiceFetch = async (voiceId, text) => {
  const response = await axios.post(
    `${cartesiaApi}/text-to-speech/${voiceId}`,
    { text: text },
    {
      headers: {
        "x-api-key": apiKey,
        "Cartesia-Version": "2024-06-10",
      },
    }
  );
  return response;
};
