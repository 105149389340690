import apiClient from './index';

export const fetchAgents = async () => {
  const response = await apiClient.get('/agents');
  return response.data.items;
};

export const fetchAgentById = async (id) => {
  const response = await apiClient.get(`/agents/${id}`);
  return response.data;
};

export const fetchAgentByOrgId = async (orgId) => {
  const response = await apiClient.get(`/agents/agents-by-org/${orgId}`);
  return response.data;
};


export const createAgent = async (data) => {
  const response = await apiClient.post('/agents/create-complete-agent', data);
  return response.data;
};

export const updateAgent = async (data) => {
  const response = await apiClient.put('/agents/update-agent', data);
  return response?.data;
};

// Add other agent-related API calls here


