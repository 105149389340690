// src/pages/others/UnauthorizedPage.js
import React from 'react';
import { Button, Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const { Title, Paragraph } = Typography;

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
      <Card className="w-full max-w-3xl p-8">
        <Title level={2}>Unauthorized Access</Title>
        <Paragraph>
          You do not have permission to view this page. Please contact the administrator if you believe this is an error.
        </Paragraph>
        <Button type="primary" onClick={handleLogout}>
          Logout
        </Button>
      </Card>
    </div>
  );
};

export default UnauthorizedPage;
