import React from 'react'
import CmSoon from "../../assets/coming_soong.png"

export default function ComingSoon() {
  return (
    <div className='bg-white h-full flex items-center justify-center'>
        <img src={CmSoon} className='w-[80%] h-[80%] object-contain'/>
    </div>
  )
}
