import { Typography } from "antd";
import React from "react";
import IconLogo from "../assets/logo/leadsmotion_icon.png";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export default function LoginHeader() {
    const location = useLocation();

    let pathname = location.pathname
    let isRegistration = pathname === "/signup"
  return (
    <div className="px-20 py-2 flex items-center justify-between w-full">
      <div>
        <img className="w-10 h-10" src={IconLogo} />
      </div>
      <div className="flex">
        <Typography.Text className="block text-sm text-[#525866]">
          {isRegistration ?"Already have an account?" :"Don't have an account?"}{` `}
        </Typography.Text>
        <Typography.Text className="block text-sm font-medium ml-1 underline">
          {isRegistration ? <Link to="/login" className="text-black ">
            Login
          </Link> :<Link to="/signup" className="text-black ">
            Register
          </Link>}
        </Typography.Text>
      </div>
    </div>
  );
}
