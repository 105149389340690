// src/pages/PaymentSuccess.js
import React from 'react';
import { Card, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Card className="max-w-md w-full text-center">
        <CheckCircleOutlined style={{ fontSize: '48px', color: 'green' }} />
        <Title level={2} className="text-green-500 mt-4">Payment Successful</Title>
        <Paragraph>Your payment has been processed successfully. Thank you for your purchase!</Paragraph>
        <Button type="primary" onClick={handleGoBack} style={{ backgroundColor: '#7D4CDB', borderColor: '#7D4CDB' }}>
          Go Back to Home
        </Button>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
