import { collection, doc, setDoc, getDocs, deleteDoc, updateDoc, query, where } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

const tableName = "orgLeads"
export const createLead = async (lead) => {
  const leadRef = doc(collection(firestore, tableName));
  await setDoc(leadRef, lead);
  return leadRef.id;
};

export const fetchLeads = async (orgId) => {
  const q = query(collection(firestore, tableName), where('orgId', '==', orgId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const updateLead = async (leadId, lead) => {
  console.log(lead,"lead");
  const leadRef = doc(firestore, tableName, leadId);
  await updateDoc(leadRef, lead);
};

export const deleteLead = async (leadId) => {
  const leadRef = doc(firestore, tableName, leadId);
  await deleteDoc(leadRef);
};
