import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:8000",
  headers: {
    "Content-Type": "application/json",
    "x-vo-code-token": localStorage.getItem("voCodeToken") || "",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API call failed", error);
    return Promise.reject(error);
  }
);

export default apiClient;
