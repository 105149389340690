import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import GlobalModal from "../../components/GlobalModal";

const { Option } = Select;

const CreateLeadForm = ({ visible, loading, onCreate, onCancel, editLead }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editLead) {
      form.setFieldsValue(editLead);
    } else {
      form.resetFields();
    }
  }, [editLead, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate({...values,status: "not_called_yet",});
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <GlobalModal
    loading={loading}
      visible={visible}
      title={editLead ? "Edit contact" : "Create new contact"}
      onCancel={onCancel}
      onOk={handleSubmit}
      requiredPermission={editLead ?"canEditLeads" :"canCreateLeads"}
      // footer={[
      //   <Button key="back" onClick={onCancel}>
      //     Cancel
      //   </Button>,
      //   <Button
      //     key="submit"
      //     type="primary"
      //     loading={loading}
      //     onClick={handleSubmit}
      //   >
      //     {editLead ? "Update" : "Create"}
      //   </Button>,
      // ]}
    >
      <Form form={form} layout="vertical" name="create_lead_form">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: "Please input the phone number with county code without + symbol" },
          ]}
        >
          <Input placeholder="Phone number with county code without + symbol" />
        </Form.Item>
      </Form>
    </GlobalModal>
  );
};

export default CreateLeadForm;
