// // src/App.js
// import React from "react";
// import { Layout, Typography } from "antd";
// import { BrowserRouter as Router } from "react-router-dom";
// import { AuthProvider } from "./AuthContext";
// import SidebarMenu from "./SidebarMenu";
// import AppRoutes from "./AppRoutes";

// const { Header, Sider, Content } = Layout;

// function App() {
//   console.log(process.env,"--process.env");
//   return (
//     <AuthProvider>
//       <Router>
//         <Layout className="min-h-screen">
//           <Sider collapsible className="bg-gray-800">
//             <SidebarMenu />
//           </Sider>
//           <Layout>
//             <Header className="bg-gray-700 p-0 flex items-center justify-between px-10">
//               <Typography.Text className="text-white font-bold text-xl">
//                 <span className="text-blue-500 ">Leads</span>Motions
//               </Typography.Text>
//             </Header>
//             <Content className="m-4">
//               <AppRoutes />
//             </Content>
//           </Layout>
//         </Layout>
//       </Router>
//     </AuthProvider>
//   );
// }

// export default App;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppLayout from "./layouts/Layout";
import AppRoutes from "./routes/AppRoutes";
import { AuthProvider } from "./AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const App = () => {
  
  return (
      <Router>
        <AuthProvider>
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </AuthProvider>
      </Router>
  );
};

export default App;
