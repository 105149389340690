// src/pages/RoleManagement.js
import React, { useState, useEffect } from "react";
import { Button, Table, Form, Input, message } from "antd";
import {
  getAllRoles,
  createRole,
  updateRole,
  deleteRole,
} from "../../api/firebase/roleApi";
import withAuthorization from "../../hoc/withAuthorization";
import GlobalDeleteModal from "../../components/GlobalDeleteModal";
import GlobalModal from "../../components/GlobalModal";

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const [deleteRoleId, setDeleteRoleId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const roles = await getAllRoles();
      setRoles(roles);
      console.log(roles);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch roles");
      setLoading(false);
    }
  };

  const handleCreate = () => {
    setEditRole(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (role) => {
    setEditRole(role);
    setIsModalVisible(true);
    form.setFieldsValue(role);
  };

  const handleDelete = (roleId) => {
    setDeleteRoleId(roleId);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteRole(deleteRoleId);
      message.success("Role deleted successfully");
      fetchRoles();
    } catch (error) {
      message.error("Failed to delete role");
      setLoading(false);
    }
    setIsDeleteModalVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = form.getFieldsValue();
      if (editRole) {
        await updateRole(editRole.id, values);
        message.success("Role updated successfully");
      } else {
        await createRole({ id: values?.name?.toLowerCase(), ...values });
        message.success("Role created successfully");
      }
      setIsModalVisible(false);
      fetchRoles();
    } catch (error) {
      message.error("Failed to save role");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button className="mr-2" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button onClick={() => handleDelete(record.id)} danger>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div className="p-10">
      <Button type="primary" onClick={handleCreate} className="mb-4">
        Create Role
      </Button>
      <Table
        columns={columns}
        dataSource={roles}
        loading={loading}
        rowKey="id"
      />
      <GlobalModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        loading={loading}
        requiredPermission={editRole ? "canUseRolesEdit" : "canUseRolesCreate"}
        title={editRole ? "Edit Role" : "Create Role"}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter the role name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the role description" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </GlobalModal>
      <GlobalDeleteModal
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        loading={loading}
        requiredPermission="canUseRolesDelete"
        title="Confirm Deletion"
      />
    </div>
  );
};

export default withAuthorization(RoleManagement, "canUseRolesList");
