import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Space,
  Input,
  Tabs,
  Tag,
  Dropdown,
  Menu,
  Popconfirm,
  message,
  Drawer,
  Form,
  Select,
  Modal,
  List,
} from "antd";
import {
  UserAddOutlined,
  DeleteOutlined,
  ImportOutlined,
  FilterOutlined,
  EllipsisOutlined,
  PhoneOutlined,
  EditOutlined,
  DownOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  updateAllStatusByIds,
  updateMainUser,
} from "../../api/userApi";
import { createNewCall } from "../../api/callApi";
import CSVUploadComponent from "../CSVUploader";
import CreateUserForm from "../../components/CreateUserForm";
import { fetchAgents } from "../../api/agentApi";
import { firestore } from "../../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [bulkDeleteModalVisible, setBulkDeleteModalVisible] = useState(false);
  const [changeStatusModalVisible, setChangeStatusModalVisible] =
    useState(false);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] =
    useState(false);
  const [editUser, setEditUser] = useState(null);
  const [statusUpdateModalVisible, setStatusUpdateModalVisible] =
    useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [statusToUpdate, setStatusToUpdate] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedRole, setSelectedRole] = useState(
    localStorage.getItem("userRoleFilter") || "all"
  );
  const [form] = Form.useForm();
  const [statusForm] = Form.useForm();
  const { organization, fetchOrganization, userData } = useAuth();
  const navigate = useNavigate();

  const fetchUsersLocal = async () => {
    try {
      const usersQuery = query(
        collection(firestore, "users"),
        where("orgId", "==", userData?.orgId)
      );

      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          key: doc.id,
          ...data,
        };
      });

      fetchOrganization(userData?.orgId);
      setUsers(users);
      filterUsers(users, selectedRole);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Failed to fetch users");
      console.error("Failed to fetch users:", error);
    }
  };

  const onUsersChange = (updatedUsers) => {
    setUsers(updatedUsers);
    filterUsers(updatedUsers, selectedRole);
  };

  useEffect(() => {
    fetchUsersLocal();
  }, []);

  const filterUsers = (users, role) => {
    let filteredData = users;
    if (role !== "all") {
      filteredData = users.filter((user) => user.role === role);
    }
    setFilteredUsers(filteredData);
  };

  const handleRoleFilterChange = (value) => {
    setSelectedRole(value);
    localStorage.setItem("userRoleFilter", value);
    filterUsers(users, value);
  };

  const handleStatusChange = () => {};

  const fetchAgentsLocal = async () => {
    try {
      const agentsQuery = query(collection(firestore, "agents"), where("orgId", "==", userData?.orgId));
      const agentsSnapshot = await getDocs(agentsQuery);
      const agentsData = agentsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          key: doc.id,
          ...data,
        };
      });

      setAgents(agentsData);
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch agents:", error);
    }
  };

  useEffect(() => {
    fetchAgentsLocal();
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleBulkDelete = async () => {
    setLoading(true);
    try {
      await Promise.all(selectedRowKeys.map((id) => deleteUser(id)));
      const updatedUsers = users.filter(
        (user) => !selectedRowKeys.includes(user.key)
      );
      onUsersChange(updatedUsers);
      setFilteredUsers(updatedUsers);
      setSelectedRowKeys([]);
      message.success("Users deleted successfully.");
    } catch (error) {
      message.error("Failed to delete users.");
      console.error("Failed to delete users:", error);
    }
    setLoading(false);
    setBulkDeleteModalVisible(false);
  };

  const handleDelete = async (key) => {
    setLoading(true);
    try {
      await deleteUser(key);
      const updatedUsers = users.filter((user) => user.key !== key);
      onUsersChange(updatedUsers);
      setFilteredUsers(updatedUsers);
      message.success("User deleted successfully.");
    } catch (error) {
      message.error("Failed to delete user.");
      console.error("Failed to delete user:", error);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    const filteredData = users?.filter((user) =>
      user.name?.toLowerCase().includes(value?.toLowerCase())
    );
    setFilteredUsers(filteredData);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const showBulkDeleteModal = () => {
    setBulkDeleteModalVisible(true);
  };

  const closeBulkDeleteModal = () => {
    setBulkDeleteModalVisible(false);
  };

  const showChangeStatusModal = () => {
    setChangeStatusModalVisible(true);
  };

  const closeChangeStatusModal = () => {
    setChangeStatusModalVisible(false);
  };

  const handleFilter = (values) => {
    const { role, status } = values;
    let filteredData = users;
    if (role) {
      filteredData = filteredData.filter((user) => user.role === role);
    }
    if (status) {
      filteredData = filteredData.filter((user) => user.status === status);
    }
    setFilteredUsers(filteredData);
    closeDrawer();
  };

  const handleConfirmStatusUpdate = async () => {
    const updatedUsers = [...users];
    const userIndex = updatedUsers.findIndex(
      (user) => user.id === userToUpdate.id
    );
    if (userIndex !== -1) {
      updatedUsers[userIndex].status = statusToUpdate;

      try {
        await updateUser(userToUpdate.id, { status: statusToUpdate });

        onUsersChange(updatedUsers);
        setUserToUpdate(null);
        setStatusToUpdate("");
        message.success("User status updated successfully.");
      } catch (error) {
        message.error("Failed to update user status on the server.");
        console.error("Failed to update user status on the server:", error);
      }
    }
  };

  const handleCreateUser = async (values) => {
    if(!userData?.orgId){
      message.error("You are not authorized")
      return
    }
    setLoading(true);
    try {
      const response = await createUser({ ...values, orgId:userData?.orgId, roleId: "user" });
      message.success("User created successfully.");
      fetchUsersLocal()
      setIsCreateUserModalVisible(false);
    } catch (error) {
      message.error("Failed to create user.");
      console.error("Failed to create user:", error);
    }
    setLoading(false);
  };

  const handleEditUser = async (values) => {
    setLoading(true);
    try {
      await updateMainUser({
        userId: editUser.id,
        updateData: values,
      });
      const updatedUsers = users.map((user) =>
        user.id === editUser.key ? { ...user, ...values } : user
      );
      onUsersChange(updatedUsers);
      setIsCreateUserModalVisible(false);
      setEditUser(null);
      message.success("User updated successfully.");
    } catch (error) {
      message.error("Failed to update user.");
      console.error("Failed to update user:", error);
    }
    setLoading(false);
  };

  const handleDeleteUser = async (userId) => {
    setLoading(true);
    try {
      await deleteUser(userId);
      const updatedUsers = users.filter((user) => user.id !== userId);
      onUsersChange(updatedUsers);
      message.success("User deleted successfully.");
    } catch (error) {
      message.error("Failed to delete user.");
      console.error("Failed to delete user:", error);
    }
    setLoading(false);
  };

  const handleChangeMultipleStatus = () => {
    if (selectedRowKeys.length === 0) {
      message.error("No users selected.");
      return;
    }
    setStatusUpdateModalVisible(true);
  };

  const confirmChangeMultipleStatus = async () => {
    setLoading(true);
    try {
      await updateAllStatusByIds({
        userIds: selectedRowKeys,
        status: statusToUpdate,
      });

      const updatedUsers = users.map((user) =>
        selectedRowKeys.includes(user.id)
          ? { ...user, status: statusToUpdate }
          : user
      );
      onUsersChange(updatedUsers);
      setStatusUpdateModalVisible(false);
      setSelectedRowKeys([]);
      setStatusToUpdate("");
      setLoading(false);
      message.success("User statuses updated successfully.");
    } catch (error) {
      message.error("Failed to update user statuses.");
      console.error("Failed to update user statuses:", error);
      setLoading(false);
    }
  };

  const callAPI = async () => {
    const credRemain =
     organization?.leftoverMinutes
    if (!organization?.totalAllocatedMinutes || credRemain < 1) {
      message.error("Please recharge your wallet!");
      navigate("/pricing");
      return;
    }

    if (!agents.length || !selectedAgent) {
      message.error("No agents available to call.");
      return;
    }
    setLoading(true);
    for (const userId of selectedRowKeys) {
      const userIndex = users.findIndex((user) => user.key === userId);
      if (userIndex !== -1) {
        const updatedUsers = [...users];
        updatedUsers[userIndex].status = "Processing...";
        onUsersChange(updatedUsers);

        try {
          const toNumber = `${updatedUsers[userIndex].phone}`;

          await createNewCall({
            toNumber,
            voCodeAgent: selectedAgent,
            user: userData,
          });

          updatedUsers[userIndex].status = "Done";
          console.log(
            `API call successful for user ${updatedUsers[userIndex].name}`
          );
        } catch (error) {
          updatedUsers[userIndex].status = "Failed";
          console.error(
            `Failed to call API for user ${updatedUsers[userIndex].name}:`,
            error
          );
        }

        onUsersChange(updatedUsers);
      }
    }
    setLoading(false);
  };

  const handleExport = () => {
    const csvData = filteredUsers.map((user) => ({
      name: user.name,
      email: user.email,
      phone: user.phone,
      status: user.status,
    }));

    const csvString = [
      ["Name", "Email", "Phone", "Status"],
      ...csvData.map((item) => [
        item.name,
        item.email,
        item.phone,
        item.status,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "users.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "default";
        if (status === "Appointment Booked") color = "green"; // remove this condition Appointment Booked with succesful calls
        else if (status === "Called") color = "blue";
        else if (status === "Not Called") color = "red";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  setEditUser(record);
                  setIsCreateUserModalVisible(true);
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item key="2">
                <Popconfirm
                  title="Are you sure to delete this user?"
                  onConfirm={() => handleDeleteUser(record.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  Delete
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div
      className={`min-h-screen bg-gray-100 p-8 ${
        modalVisible ? "blurry-background" : ""
      }`}
    >
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">Users</h1>
          <Space>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={showBulkDeleteModal}
              disabled={selectedRowKeys.length === 0}
            >
              Bulk Delete
            </Button>
            <Button icon={<ImportOutlined />} onClick={showModal}>
              Import CSV
            </Button>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleExport}
              style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
            >
              Export Users
            </Button>
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
              onClick={() => setIsCreateUserModalVisible(true)}
            >
              Create User
            </Button>
          </Space>
        </div>
        <div className="flex justify-between items-center mb-4">
          <Search
            placeholder="Search"
            onSearch={handleSearch}
            style={{ width: 200 }}
          />
          <Space>
            <Select
              placeholder="Filter by Role"
              style={{ width: 200 }}
              onChange={handleRoleFilterChange}
              value={selectedRole}
            >
              <Option value="all">All</Option>
              <Option value="admin">Admin</Option>
              <Option value="manager">Manager</Option>
              <Option value="administrator">Administrator</Option>
              <Option value="user">User</Option>
            </Select>
            <Select
              placeholder="Select Agent"
              style={{ width: 200 }}
              onChange={(value) => setSelectedAgent(value)}
              value={selectedAgent}
            >
              {agents.map((agent) => (
                <Option key={agent.agentResponse.id} value={agent.agentResponse.id}>
                  {agent.agentResponse.name}
                </Option>
              ))}
            </Select>
            <Button
              icon={<PhoneOutlined />}
              onClick={callAPI}
              disabled={selectedRowKeys.length === 0 || !selectedAgent}
            >
              Call Now
            </Button>
            <Button
              disabled={selectedRowKeys.length === 0}
              icon={<EditOutlined />}
              onClick={showChangeStatusModal}
            >
              Change Status
            </Button>
            <Button icon={<FilterOutlined />} onClick={showDrawer}>
              Filters
            </Button>
          </Space>
        </div>
        <Tabs defaultActiveKey="1" className="custom-tabs">
          <TabPane tab="View all" key="1">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredUsers}
              loading={loading}
              pagination={{ pageSize: 5 }}
              className="bg-white p-4 rounded-lg border"
            />
          </TabPane>
          <TabPane tab="Not Called" key="2">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredUsers.filter(
                (user) => user.status === "Not Called"
              )}
              loading={loading}
              pagination={{ pageSize: 5 }}
              className="bg-white p-4 rounded-lg border"
            />
          </TabPane>
          <TabPane tab="Called" key="3">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredUsers.filter(
                (user) => user.status === "Called"
              )}
              loading={loading}
              pagination={{ pageSize: 5 }}
              className="bg-white p-4 rounded-lg border"
            />
          </TabPane>
          <TabPane tab="Other" key="5">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredUsers.filter(
                (user) =>
                  user.status !== "Not Called" &&
                  user.status !== "Called" &&
                  user.status !== "Appointment Booked" // remove this condition
              )}
              loading={loading}
              pagination={{ pageSize: 5 }}
              className="bg-white p-4 rounded-lg border"
            />
          </TabPane>
        </Tabs>
        <Drawer
          title="Filter Users"
          placement="bottom"
          closable={true}
          onClose={closeDrawer}
          visible={drawerVisible}
          height={300}
        >
          <Form form={form} layout="vertical" onFinish={handleFilter}>
            <Form.Item name="role" label="Role">
              <Select placeholder="Select role">
                <Option value="General">General</Option>
                <Option value="Admin">Admin</Option>
                <Option value="Creator">Creator</Option>
              </Select>
            </Form.Item>
            <Form.Item name="status" label="Status">
              <Select placeholder="Select status">
              <Option value="Answered Calls">Answered Calls</Option>
              <Option value="Not Answered">Not Answered Calls</Option>
              <Option value="Not Called Yet">Not Called Yet</Option>
              <Option value="Successful Calls">Successful Calls</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#7D4CDB", borderColor: "#7D4CDB" }}
              >
                Apply Filters
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
        <Modal
          title="Import CSV"
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          bodyStyle={{ backdropFilter: "blur(10px)" }}
        >
          <CSVUploadComponent />
        </Modal>
        <Modal
          title="Confirm Bulk Delete"
          visible={bulkDeleteModalVisible}
          onCancel={closeBulkDeleteModal}
          onOk={handleBulkDelete}
          okText="Delete"
          okButtonProps={{ danger: true }}
          cancelText="Discard"
        >
          <List
            dataSource={selectedRowKeys.map((key) =>
              filteredUsers.find((user) => user.key === key)
            )}
            renderItem={(user) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() =>
                      setSelectedRowKeys(
                        selectedRowKeys.filter((k) => k !== user.key)
                      )
                    }
                  >
                    Discard
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={user.name}
                  description={`Email: ${user.email}, Phone: ${user.phone}`}
                />
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          title="Change Status"
          visible={changeStatusModalVisible}
          onCancel={closeChangeStatusModal}
          onOk={() => statusForm.submit()}
          okText="Change"
          cancelText="Discard"
        >
          <Form
            form={statusForm}
            layout="vertical"
            onFinish={handleStatusChange}
          >
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <Select placeholder="Select status">
              <Option value="Answered Calls">Answered Calls</Option>
              <Option value="Not Answered">Not Answered Calls</Option>
              <Option value="Not Called Yet">Not Called Yet</Option>
              <Option value="Successful Calls">Successful Calls</Option>
              </Select>
            </Form.Item>
          </Form>
          <List
            dataSource={selectedRowKeys.map((key) =>
              filteredUsers.find((user) => user.key === key)
            )}
            renderItem={(user) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() =>
                      setSelectedRowKeys(
                        selectedRowKeys.filter((k) => k !== user.key)
                      )
                    }
                  >
                    Discard
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={user.name}
                  description={`Email: ${user.email}, Phone: ${user.phone}`}
                />
              </List.Item>
            )}
          />
        </Modal>
        <CreateUserForm
          visible={isCreateUserModalVisible}
          onCreate={editUser ? handleEditUser : handleCreateUser}
          onCancel={() => {
            setIsCreateUserModalVisible(false);
            setEditUser(null);
          }}
          user={editUser}
        />
      </div>
    </div>
  );
};

export default Users;
