import React from "react";
import CallPage from "./CallPage";

export default function InComingCalls() {
  return (
    <div>
      <CallPage title="Incoming Calls" type="inboundPhoneCall" />{" "}
    </div>
  );
}
