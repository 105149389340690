import { firestore } from "../../firebaseConfig";
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, getDoc, where, query, or } from "firebase/firestore";

const plansCollection = collection(firestore, "plans");

const PLANS_COLLECTION = "plans";

export const createPlan = async (plan) => {
  const docRef = await addDoc(plansCollection, plan);
  return { id: docRef.id, ...plan };
};


const removeUndefinedAndNullValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj.filter((item) => item !== null && item !== undefined).map(removeUndefinedAndNullValues);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, value]) => value !== undefined && value !== null)
        .map(([key, value]) => [key, removeUndefinedAndNullValues(value)])
    );
  } else {
    return obj;
  }
};

export const updatePlan = async (id, plan) => {
  const sanitizedPlan = removeUndefinedAndNullValues(plan);
  const planDoc = doc(plansCollection, id);
  await updateDoc(planDoc, sanitizedPlan);
};

export const deletePlan = async (id) => {
  const planDoc = doc(plansCollection, id);
  await deleteDoc(planDoc);
};


export const fetchPlans = async () => {
    const querySnapshot = await getDocs(collection(firestore, PLANS_COLLECTION));
    const plans = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return plans;
  };

  export const fetchFreePlan = async () => {
    const orgIdQuery = query(
      collection(firestore, PLANS_COLLECTION),
      where('price', '==', "0"),
    );
    const orgIdSnapshot = await getDocs(orgIdQuery);
    const plan = orgIdSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return plan;
  };
  
  


export const fetchPlansByOrgId = async (orgId) => {
  try {
    const plans = [];

    if (orgId) {
      const orgIdQuery = query(
        collection(firestore, PLANS_COLLECTION),
        where('enabled', '==', true),
        where('orgId', '==', orgId)
      );
      const orgIdSnapshot = await getDocs(orgIdQuery);
      const orgIdPlans = orgIdSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      plans.push(...orgIdPlans);
    }

    const noOrgIdQuery = query(
      collection(firestore, PLANS_COLLECTION),
      where('enabled', '==', true),
      where('orgId', '==', '')
    );
    const noOrgIdSnapshot = await getDocs(noOrgIdQuery);
    const noOrgIdPlans = noOrgIdSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    plans.push(...noOrgIdPlans);

    const noOrgFieldQuery = query(
      collection(firestore, PLANS_COLLECTION),
      where('enabled', '==', true)
    );
    const noOrgFieldSnapshot = await getDocs(noOrgFieldQuery);
    const noOrgFieldPlans = noOrgFieldSnapshot.docs
      .filter((doc) => !doc.data().hasOwnProperty('orgId'))
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    plans.push(...noOrgFieldPlans);

    return plans;
  } catch (error) {
    console.error('Error fetching plans:', error);
    return [];
  }
};


  export const fetchPlanById = async (planId) => {
    const docRef = doc(firestore, PLANS_COLLECTION, planId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      throw new Error("Plan not found");
    }
  };
