import React, { useEffect, useState } from 'react';
import { Table, Button, Spin, Typography, Checkbox, Modal } from 'antd';
import { fetchActions } from '../../api/actionApi';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { useAuth } from '../../AuthContext';

const { Title } = Typography;

const Actions = () => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate(); 

  const {userData} = useAuth()

  const allColumns = [
    {
      title: 'Name',
      dataIndex: ['config', 'name'],
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Description',
      dataIndex: ['config', 'description'],
      key: 'description',
    },
    {
      title: 'Phone Number',
      dataIndex: ['config', 'phoneNumber'],
      key: 'phoneNumber',
      render: (text, record) =>
        record.type === 'action_transfer_call' ? text : '',
    },
    {
      title: 'Input Schema',
      key: 'inputSchema',
      render: (text, record) =>
        record.type === 'action_external' ? (
          <ul>
            {record.config.inputSchema.properties &&
              Object.keys(record.config.inputSchema.properties).map((key) => (
                <li key={key}>
                  {key}: {record.config.inputSchema.properties[key].type}
                </li>
              ))}
          </ul>
        ) : (
          ''
        ),
    },
  ];

  useEffect(() => {
    const storedColumns = localStorage.getItem('selectedActionColumns');
    if (storedColumns) {
      setSelectedColumns(JSON.parse(storedColumns));
    } else {
      setSelectedColumns(allColumns.map((col) => col.key));
    }
    getActionsData(page, pageSize);
  }, [page, pageSize]);

  const getActionsData = async (page, pageSize) => {
    try {
      setLoading(true);
      const response = await fetchActions(page, pageSize,userData?.orgId);
      setActions(response.items);
      setTotal(response.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching actions:', error);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleEditColumns = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    localStorage.setItem('selectedActionColumns', JSON.stringify(selectedColumns));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  const columns = allColumns.filter((col) => selectedColumns.includes(col.key));

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="flex items-center justify-between mb-4">
        <Title level={2}>Actions</Title>
        <div>
          <Button
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={handleEditColumns}
          >
            Edit Columns
          </Button>
       
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={actions}
          rowKey="id"
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
          className="bg-white p-4 rounded-lg"
        />
      )}
      <Modal
        title="Edit Columns"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          value={selectedColumns}
          onChange={handleCheckboxChange}
        >
          {allColumns.map((col) => (
            <Checkbox key={col.key} value={col.key}>
              {col.title}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Modal>
    </div>
  );
};

export default Actions;
