import { Typography } from "antd";
import React from "react";
import IconLogo from "../assets/logo/leadsmotion_icon.png";

export default function FooterHeader() {
  return (
    <div className="px-20 py-2 flex items-center justify-between w-full">
      <div>
        <Typography.Text className="text-sm text-[#525866]">
          © {new Date().getFullYear()} Agentel AI
        </Typography.Text>
      </div>
    </div>
  );
}
