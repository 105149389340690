import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { app, firestore } from "./firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Spin, message } from "antd";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [serviceConfig, setServiceConfig] = useState({ service: "vapi" }); // default service

  const auth = getAuth(app);

  useEffect(() => {
    const fetchOrganization = async (orgId) => {
      try {
        const orgDoc = await getDoc(doc(firestore, "organizations", orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          setOrganization({
            ...orgData,
            leftoverMinutes:
              (orgData.totalAllocatedMinutes + orgData.freePlanMinutes) -
              (orgData.usedMinutes || 0),
          });

          if (orgData.planId) {
            fetchPlanById(orgData.planId);
          }
        } else {
          console.error("Organization not found");
        }
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    };

    const fetchServiceConfig = async () => {
      try {
        const configDoc = await getDoc(doc(firestore, "configurations", "serviceConfig"));
        if (configDoc.exists()) {
          setServiceConfig(configDoc.data());
        } else {
          setServiceConfig({ service: "vapi" }); // default service
        }
      } catch (error) {
        console.error("Error fetching service configuration:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !signupInProgress) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUser(user);
          setUserData(data);
          localStorage.setItem("token", user.accessToken);
          if (data.orgId) {
            fetchOrganization(data.orgId);
          }
          fetchServiceConfig(); // Fetch the service configuration
          setLoading(false);
        } else {
          await signOut(auth);
          setUser(null);
          setUserData(null);
          window.location.href = "/unauthorized";
        }
      } else {
        setUser(null);
        setUserData(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      if (intervalId) clearInterval(intervalId);
    };
  }, [auth, signupInProgress]);

  const setOrganizationFetchInterval = (minutes) => {
    if (intervalId) clearInterval(intervalId);
    const newIntervalId = setInterval(() => {
      if (userData?.orgId) {
        fetchOrganization(userData.orgId);
      }
    }, minutes * 60000);
    setIntervalId(newIntervalId);
  };

  const fetchOrganization = async (orgId) => {
    try {
      const orgDoc = await getDoc(doc(firestore, "organizations", orgId));
      if (orgDoc.exists()) {
        const orgData = orgDoc.data();
        setOrganization(orgData);
        if (orgData.planId) {
          fetchPlanById(orgData.planId);
        }
      } else {
        console.error("Organization not found");
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };

  const fetchPlanById = async (planId) => {
    try {
      const planDoc = await getDoc(doc(firestore, "plans", planId));
      console.log(planDoc.data());
      if (planDoc.exists()) {
        setPlan(planDoc.data());
      } else {
        console.error("Plan not found");
      }
    } catch (error) {
      console.error("Error fetching plan details:", error);
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const email = result.user.email;
      const userId = result.user.uid;

      const userDoc = await getDoc(doc(firestore, "users", userId));

      if (!userDoc.exists()) {
        await setDoc(doc(firestore, "users", userId), {
          email: email,
          role: "admin",
          verified: false,
          createdAt: new Date(),
        });

        message.success("Login successful");
      } else {
        await signOut(auth);
        message.error("Not authorized. Please use an authorized email.");
      }
    } catch (error) {
      message.error("Login failed. Please try again.");
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  if (!plan && userData && userData?.roleId !== "owner")
    return (
      <div className="flex items-center justify-center w-full h-[100vh]">
        <Spin />
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        user,
        userData,
        organization,
        plan,
        serviceConfig,
        loginWithGoogle,
        logout,
        setSignupInProgress,
        setOrganizationFetchInterval,
        fetchOrganization,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
