import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Input, DatePicker, Select, message } from 'antd';
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import axios from 'axios';
import apiClient from '../../api/index';
import moment from 'moment-timezone';

const { Option } = Select;

const Campaigns = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const orgSnapshot = await getDocs(collection(firestore, "organizations"));
        const orgs = orgSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrganizations(orgs);
      } catch (error) {
        message.error("Error fetching organizations");
      }
    };

    fetchOrganizations();
  }, []);

  const fetchUsers = async (orgId) => {
    setLoading(true);
    try {
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      const usersList = usersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(user => user.orgId === orgId);
      setUsers(usersList);
    } catch (error) {
      message.error("Error fetching users");
    } finally {
      setLoading(false);
    }
  };

  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    fetchUsers(value);
  };

  const handleSubmit = async (values) => {
    const { campaignName, campaignTime } = values;
    const formattedTime = moment(campaignTime).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
    try {
      await apiClient.post('/campaigns', {
        campaignName,
        campaignTime: formattedTime,
        users: selectedUsers
      });
      message.success('Campaign created successfully');
    } catch (error) {
      message.error('Error creating campaign');
    }
  };

  return (
    <div className="p-6">
      <Card className="w-full">
        <h2 className="text-xl mb-4">Create Campaign</h2>
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="campaignName"
            label="Campaign Name"
            rules={[{ required: true, message: 'Please input the campaign name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="campaignTime"
            label="Campaign Time"
            rules={[{ required: true, message: 'Please select the campaign time' }]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item
            name="organization"
            label="Select Organization"
            rules={[{ required: true, message: 'Please select an organization' }]}
          >
            <Select onChange={handleOrgChange}>
              {organizations.map(org => (
                <Option key={org.id} value={org.id}>{org.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="users"
            label="Select Users"
            rules={[{ required: true, message: 'Please select users' }]}
          >
            <Select
              mode="multiple"
              value={selectedUsers}
              onChange={setSelectedUsers}
              loading={loading}
            >
              {users.map(user => (
                <Option key={user.id} value={user.id}>{user.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Create Campaign</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Campaigns;
