import React from "react";
import { useAuth } from "../AuthContext";
import NotAuthorizedPage from "../pages/auth/NotAuthorizedPage";
import UpgardePlanPage from "../pages/auth/UpgardePlanPage";

const withAuthorization = (WrappedComponent, requiredPermission) => {
  return (props) => {
    const { userData, plan } = useAuth();

    // Function to check if the required permission exists in the permissions array
    const hasPermission = (permissions, permissionName) => {
      return permissions?.some(permission => permission.name === permissionName);
    };

    // Owners always have access
    if (userData.roleId === "owner") {
      return <WrappedComponent {...props} />;
    }

    // Admins need to have the required permission in the plan
    if (userData.roleId === "admin") {
      if (hasPermission(plan?.permissions, requiredPermission)) {
        return <WrappedComponent {...props} />;
      } else {
        return <UpgardePlanPage />;
      }
    }

    // Regular users need to have the required permission in their user permissions
    if (hasPermission(userData?.permissions, requiredPermission)) {
      return <WrappedComponent {...props} />;
    } else {
      return <NotAuthorizedPage />;
    }
  };
};

export default withAuthorization;
