import { Card, DatePicker, Button, message, Typography, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CallsChart from "../chatt/CallChart";
import AgentStats from "../chatt/AgentStats";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import moment from "moment";
import Campaigns from "../campaigns/Campaigns";
import { useAuth } from "../../AuthContext";

const { RangePicker } = DatePicker;
const { Title, Paragraph } = Typography;

export default function Dashboard() {
  const [callsData, setCallsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState();
  const [loading, setLoading] = useState(true);

  const {userData} = useAuth()
  
  useEffect(() => {
    fetchCallsData();
  }, []);

  const fetchCallsData = async () => {
    try {
      const orgId = userData?.orgId;
      // Create a query with a where clause to filter by orgId
      const callsQuery = query(
        collection(firestore, "calls"),
        where("orgId", "==", orgId)
      );

      // Execute the query
      const callsSnapshot = await getDocs(callsQuery);

      const fetchedCalls = callsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          key: doc.id,
          startTime: data.startTime
            ? new Date(data.startTime.seconds * 1000)
            : null,
          endTime: data.endTime ? new Date(data.endTime.seconds * 1000) : null,
        };
      });

      const validCalls = fetchedCalls.filter(
        (call) => call.startTime && call.endTime
      );
      setCallsData(validCalls);
      setFilteredData(validCalls);
    } catch (error) {
      message.error("Error fetching calls data");
      console.error("Error fetching calls data:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    const filtered = callsData?.filter((call) => {
      const callDate = moment(call.startTime);
      return callDate.isBetween(dateRange[0], dateRange[1], null, "[]");
    });
    setFilteredData(filtered);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const handleSubmit = () => {
    filterData();
  };

  return (
    <div className="p-6">
      <Title level={2}>Analytics</Title>
      <Paragraph>
        Here you can view the statistics and performance of your calls and
        agents.
      </Paragraph>

      {/* <div className="mb-4 flex items-center space-x-4">
        <RangePicker
          value={dateRange}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          disabledDate={current => current && current > moment().endOf('day')}
        />
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div> */}

      <div className="flex justify-between ">
        <Card className="w-[70%]">
          {loading ? <Spin /> : <CallsChart data={filteredData} />}
        </Card>
        <Card className="w-[28%]">
          {loading ? <Spin /> : <AgentStats data={filteredData} />}
        </Card>
      </div>
    </div>
  );
}
