import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Space,
  Button,
  message,
  Switch,
  Typography,
  Breadcrumb,
  Divider,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  fetchOrganizationsByOwner,
  updateOrganization,
} from "../../api/organizationApi";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../components/GenericTable";

const { Title } = Typography;

const ListOfOrgs = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    loadOrganizations();
  }, []);

  const loadOrganizations = async () => {
    setLoading(true);
    try {
      const orgs = await fetchOrganizationsByOwner(user.uid);
      setOrganizations(orgs);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch organizations");
      setLoading(false);
    }
  };

  const handleUpdateOrganization = async (orgId, data) => {
    setLoading(true);
    try {
      await updateOrganization(orgId, data);
      message.success("Organization updated successfully");
      loadOrganizations();
    } catch (error) {
      message.error("Failed to update organization");
      setLoading(false);
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Owner ID", dataIndex: "ownerId", key: "ownerId" },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      render: (verified, record) => (
        <Switch
          checked={verified}
          onChange={(checked) =>
            handleUpdateOrganization(record.id, { verified: checked })
          }
        />
      ),
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled, record) => (
        <Switch
          checked={enabled}
          onChange={(checked) =>
            handleUpdateOrganization(record.id, { enabled: checked })
          }
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          {/* <Button
            icon={<EditOutlined />}
            onClick={() => {
            }}
          >
            Edit
          </Button> */}

          <Button
            onClick={() => navigate(`/organizations/${record.id}/members`)}
          >
            Members
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="bg-white w-full h-full">
      <div className="px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "All Organizations",
            },
          ]}
        />
        <Divider />
      </div>{" "}
      <div className="w-full mb-8">
        <GenericTable
          data={organizations}
          columns={columns}
          loading={loading}
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default ListOfOrgs;
