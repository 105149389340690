import { Typography } from 'antd'
import React from 'react'
import requireStar from "../assets/requiredStar.png"

export default function Label({children,required}) {
  return (
    <div className='flex items-center'>
        <Typography.Text className='text-xs'>
        {children} 
    </Typography.Text>

    {required && <img className='w-2 h-2 mt-[-4px] ml-[1px]' src={requireStar} />}
    </div> 
  )
}
