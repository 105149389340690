import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  Modal,
  message,
  Switch,
  Typography,
  Divider,
  Select,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createPlan,
  updatePlan,
  deletePlan,
  fetchPlans,
} from "../../api/firebase/planApi";
import {
  createFeature,
  updateFeature,
  deleteFeature,
  fetchFeatures,
} from "../../api/firebase/featureApi";
import { removeEmptyValues } from "../../utils/helper";
import { useAuth } from "../../AuthContext";
import { fetchOrganizationsByOwner } from "../../api/organizationApi";
import { fetchPermissions } from "../../api/firebase/permissionApi";

const { Title, Text } = Typography;
const { Option } = Select;

const PlanManagement = () => {
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
  const [isFeatureModalVisible, setIsFeatureModalVisible] = useState(false);
  const [editPlan, setEditPlan] = useState(null);
  const [editFeature, setEditFeature] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planForm] = Form.useForm();
  const [featureForm] = Form.useForm();
  const { user } = useAuth();
  const [allOrgs, setAllOrgs] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const getAllOrgs = async () => {
    const orgs = await fetchOrganizationsByOwner(user.uid);
    setAllOrgs(orgs);
  };

  const getAllPermissions = async () => {
    const perms = await fetchPermissions();
    setPermissions(perms);
  };

  useEffect(() => {
    if (isPlanModalVisible) {
      getAllOrgs();
      getAllPermissions();
    }
  }, [isPlanModalVisible]);

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const plans = await fetchPlans();
      const sortedPlans = plans.sort((a, b) => (a.rank || Infinity) - (b.rank || Infinity));
      setPlans(sortedPlans);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch plans");
      setLoading(false);
    }
  };

  const loadFeatures = async (planId) => {
    setLoading(true);
    try {
      const features = await fetchFeatures(planId);
      setFeatures(features);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch features");
      setLoading(false);
    }
  };

  const handlePlanSubmit = async (values) => {
    setLoading(true);
    try {
      const cleanedValues = removeEmptyValues(values);
      cleanedValues.permissions = cleanedValues.permissions.map(permissionId => 
        permissions.find(permission => permission.name === permissionId)
      );
      
      if (editPlan) {
        await updatePlan(editPlan.id, {
          ...cleanedValues,
          orgId: values?.orgId || "",
        });
        
        message.success("Plan updated successfully");
      } else {
        await createPlan({ ...cleanedValues, orgId: values?.orgId || "" });
        message.success("Plan created successfully");
      }
      setIsPlanModalVisible(false);
      loadPlans();
    } catch (error) {
      console.log(error);
      message.error("Failed to save plan");
      setLoading(false);
    }
  };

  const handleFeatureSubmit = async (values) => {
    setLoading(true);
    try {
      const cleanedValues = removeEmptyValues(values);
      if (editFeature) {
        await updateFeature(editFeature.id, cleanedValues);
        message.success("Feature updated successfully");
      } else {
        await createFeature({ ...cleanedValues, planId: selectedPlan.id });
        message.success("Feature created successfully");
      }
      setIsFeatureModalVisible(false);
      loadFeatures(selectedPlan.id);
    } catch (error) {
      message.error("Failed to save feature");
      setLoading(false);
    }
  };

  const handleDeletePlan = async (planId) => {
    setLoading(true);
    try {
      await deletePlan(planId);
      message.success("Plan deleted successfully");
      loadPlans();
    } catch (error) {
      message.error("Failed to delete plan");
      setLoading(false);
    }
  };

  const handleDeleteFeature = async (featureId) => {
    setLoading(true);
    try {
      await deleteFeature(featureId);
      message.success("Feature deleted successfully");
      loadFeatures(selectedPlan.id);
    } catch (error) {
      message.error("Failed to delete feature");
      setLoading(false);
    }
  };

  const openPlanModal = (plan) => {
    setEditPlan(plan);
    if (plan) {
      planForm.setFieldsValue({
        ...plan,
        permissions: plan.permissions?.map(permission => permission.name) || []
      });
    } else {
      planForm.resetFields();
    }
    setIsPlanModalVisible(true);
  };

  const openFeatureModal = (feature) => {
    setEditFeature(feature);
    if (feature) {
      featureForm.setFieldsValue(feature);
    } else {
      featureForm.resetFields();
    }
    setIsFeatureModalVisible(true);
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
    loadFeatures(plan.id);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8 flex flex-col items-center">
      <Card className="w-full p-8 mb-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">Plan Management</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openPlanModal(null)}
          >
            Create Plan
          </Button>
        </div>
        <Table
          dataSource={plans}
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            { title: "Price", dataIndex: "price", key: "price" },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Allocated Minutes",
              dataIndex: "allocateMinutes",
              key: "allocateMinutes",
            },
            {
              title: "Rank",
              dataIndex: "rank",
              key: "rank",
              render: (text) => (text !== undefined ? text : "No Rank"),
            },
            {
              title: "Enabled",
              dataIndex: "enabled",
              key: "enabled",
              render: (text, record) => (
                <Switch checked={record.enabled} disabled />
              ),
            },
            {
              title: "Actions",
              key: "actions",
              render: (text, record) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => openPlanModal(record)}
                  >
                    Edit
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeletePlan(record.id)}
                  >
                    Delete
                  </Button>
                  <Button onClick={() => selectPlan(record)}>
                    View Features
                  </Button>
                </Space>
              ),
            },
          ]}
          loading={loading}
          rowKey="id"
        />
      </Card>
      {selectedPlan && (
        <Modal
          title={`Features for Plan: ${selectedPlan.name}`}
          onCancel={() => setSelectedPlan(null)}
          visible={selectedPlan !== null}
          footer={null}
        >
          <div className="w-full p-8">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-semibold">
                Features for Plan: {selectedPlan.name}
              </h1>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => openFeatureModal(null)}
              >
                Add Feature
              </Button>
            </div>
            {features.map((feature, index) => (
              <div key={index} className="mb-2">
                <Title level={4}>{feature.name}</Title>
                <Text>{feature.description}</Text>
                <Space style={{ float: "right" }}>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => openFeatureModal(feature)}
                  >
                    Edit
                  </Button>
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteFeature(feature.id)}
                  >
                    Delete
                  </Button>
                </Space>
                <Divider />
              </div>
            ))}
          </div>
        </Modal>
      )}
      <Modal
        title={editPlan ? "Edit Plan" : "Create Plan"}
        visible={isPlanModalVisible}
        onCancel={() => setIsPlanModalVisible(false)}
        footer={null}
      >
        <Form form={planForm} layout="vertical" onFinish={handlePlanSubmit}>
          <Form.Item
            name="name"
            label="Plan Name"
            rules={[{ required: true, message: "Please enter the plan name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please enter the plan price" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="allocateMinutes"
            label="Allocated Minutes"
            rules={[
              { required: true, message: "Please enter the allocated minutes" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="rank" label="Rank">
            <Select placeholder="Select Rank">
              {[...Array(10).keys()].map((i) => (
                <Option key={i + 1} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="permissions"
            label="Permissions"
            rules={[
              { required: true, message: "Please select at least one permission" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select Permissions"
              optionLabelProp="label"
            >
              {permissions.map(permission => (
                <Option key={permission.name} value={permission.name} label={permission.name}>
                  {permission.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="enabled" label="Enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="orgId" label="Organization">
            <Select placeholder="Select Organization">
              {allOrgs.map((_org) => (
                <Option key={_org.id} value={_org.id}>
                  {_org.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editPlan ? "Update Plan" : "Create Plan"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={editFeature ? "Edit Feature" : "Add Feature"}
        visible={isFeatureModalVisible}
        onCancel={() => setIsFeatureModalVisible(false)}
        footer={null}
      >
        <Form
          form={featureForm}
          layout="vertical"
          onFinish={handleFeatureSubmit}
        >
          <Form.Item
            name="name"
            label="Feature Name"
            rules={[
              { required: true, message: "Please enter the feature name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="premium" label="Premium" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="enabled" label="Enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editFeature ? "Update Feature" : "Add Feature"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PlanManagement;
