import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  message,
  Popconfirm,
  Breadcrumb,
  Divider,
} from "antd";
import {
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import GenericTable from "../../components/GenericTable";

const RequestedOrganizations = () => {
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const orgSnapshot = await getDocs(collection(firestore, "organizations"));
      const orgs = orgSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrganizations(orgs);
      fetchUnverifiedUsers(orgs);
    } catch (error) {
      message.error("Error fetching organizations");
      setLoading(false);
    }
  };

  const fetchUnverifiedUsers = async (orgs) => {
    setLoading(true);
    try {
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      const usersList = usersSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (user) => !user.verified && orgs.some((org) => org.id === user.orgId)
        );
      setUsers(usersList);
    } catch (error) {
      message.error("Error fetching users");
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (userId) => {
    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", userId);
      await updateDoc(userDocRef, { verified: true });
      message.success("User verified successfully");
      fetchOrganizations();
    } catch (error) {
      message.error("Error verifying user");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", userId);
      await deleteDoc(userDocRef);
      message.success("User deleted successfully");
      fetchOrganizations();
    } catch (error) {
      message.error("Error deleting user");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Organization",
      dataIndex: "orgId",
      key: "orgId",
      render: (orgId) => {
        const org = organizations.find((org) => org.id === orgId);
        return org ? org.name : "N/A";
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Popconfirm
            title="Are you sure to verify this user?"
            onConfirm={() => handleVerify(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" className="mr-2">
              Verify
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white w-full h-full">
      <div className="px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Requested Organizations",
            },
          ]}
        />
        <Divider />
      </div>

      <div >
        <GenericTable
          columns={columns}
          data={users}
          rowKey="id"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default RequestedOrganizations;
