import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Space,
  Modal,
  message,
  Breadcrumb,
  Divider,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  createPermission,
  updatePermission,
  deletePermission,
  fetchPermissions,
} from "../../api/firebase/permissionApi";
import plus_white from "../../assets/plus_white.png";
import GenericTable from "../../components/GenericTable";

const PermissionManagement = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPermissionModalVisible, setIsPermissionModalVisible] =
    useState(false);
  const [editPermission, setEditPermission] = useState(null);
  const [permissionForm] = Form.useForm();

  useEffect(() => {
    loadPermissions();
  }, []);

  const loadPermissions = async () => {
    setLoading(true);
    try {
      const permissions = await fetchPermissions();
      setPermissions(permissions);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch permissions");
      setLoading(false);
    }
  };

  const handlePermissionSubmit = async (values) => {
    setLoading(true);
    try {
      if (editPermission) {
        await updatePermission(editPermission.id, values);
        message.success("Permission updated successfully");
      } else {
        const newPermission = { id: values.name, ...values };
        await createPermission(newPermission);
        message.success("Permission created successfully");
      }
      setIsPermissionModalVisible(false);
      loadPermissions();
    } catch (error) {
      message.error("Failed to save permission");
      setLoading(false);
    }
  };

  const handleDeletePermission = async (permissionId) => {
    setLoading(true);
    try {
      await deletePermission(permissionId);
      message.success("Permission deleted successfully");
      loadPermissions();
    } catch (error) {
      message.error("Failed to delete permission");
      setLoading(false);
    }
  };

  const openPermissionModal = (permission) => {
    setEditPermission(permission);
    if (permission) {
      permissionForm.setFieldsValue(permission);
    } else {
      permissionForm.resetFields();
    }
    setIsPermissionModalVisible(true);
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="flex items-center justify-between w-full px-4 pt-4">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Permission Management",
            },
          ]}
        />

        <Button
          className="bg-black text-white flex items-center"
          onClick={() => openPermissionModal(null)}
        >
          <img src={plus_white} className="w-4 h-4 object-contain mr-1" />
          Create Permission
        </Button>
      </div>

      <Divider />

      <div className="w-full mb-8">
        <GenericTable
          data={permissions}
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Actions",
              key: "actions",
              render: (text, record) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => openPermissionModal(record)}
                  >
                    Edit
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeletePermission(record.id)}
                    danger
                  >
                    Delete
                  </Button>
                </Space>
              ),
            },
          ]}
          loading={loading}
          rowKey="id"
        />
      </div>
      <Modal
        title={editPermission ? "Edit Permission" : "Create Permission"}
        visible={isPermissionModalVisible}
        onCancel={() => setIsPermissionModalVisible(false)}
        footer={null}
      >
        <Form
          form={permissionForm}
          layout="vertical"
          onFinish={handlePermissionSubmit}
        >
          <Form.Item
            name="name"
            label="Permission Name"
            rules={[
              { required: true, message: "Please enter the permission name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editPermission ? "Update Permission" : "Create Permission"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PermissionManagement;
