import apiClient from "./index";

export const phoneNumbersCreate = async (data, orgId) => {
  const response = await apiClient.post(`/phoneNumbers?orgId=${orgId}`, data);
  return response.data;
};

export const fetchPhoneNumbers = async (orgId) => {
  const response = await apiClient.get(`/phoneNumbers?orgId=${orgId}`);
  return response.data;
};

// export const getRecording = async (id) => {
//   const response = await apiClient.get(`/calls/${id}/recording`);
//   return response.data;
// };

// export const getCallById = async (id) => {
//   const response = await apiClient.get(`/calls/getcall/${id}`);
//   return response.data;
// };

export const getPromptById = async (id) => {
  const response = await apiClient.get(`/prompts/${id}`);
  return response.data.data;
};
