import apiClient from './index';

export const getPrompt = async (id) => {
  const response = await apiClient.get(`/prompts/${id}`);
  return response.data.data;
};

export const getVoice = async (id) => {
  const response = await apiClient.get(`/voices/${id}`);
  return response.data.data;
};

export const getPromptById = async (id) => {
  const response = await apiClient.get(`/prompts/${id}`);
  return response.data.data;
};