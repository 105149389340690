import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import { startTimer } from '../../utils/timerHelper';

const TestPage = () => {
  const [organizationId, setOrganizationId] = useState('');
  const [duration, setDuration] = useState('');

  const handleStartTimer = async () => {
    try {
      const response = await startTimer(organizationId, Number(duration));
      message.success(response.message);
    } catch (error) {
      message.error('Failed to start timer');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Test Timer</h1>
      <Input
        placeholder="Organization ID"
        value={organizationId}
        onChange={(e) => setOrganizationId(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Input
        placeholder="Duration in minutes"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
        type="number"
        style={{ marginBottom: '10px' }}
      />
      <Button type="primary" onClick={handleStartTimer}>
        Start Timer
      </Button>
    </div>
  );
};

export default TestPage;
