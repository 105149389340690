import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  Spin,
  Typography,
  Card,
  Space,
  Divider,
  Checkbox,
  Modal,
} from "antd";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import languages from "../../languages";
import { fetchVoices, textToVoiceFetch } from "../../api/elevanLabsApi";
import { fetchPhoneNumbers } from "../../api/phoneNumbers";
import {
  createAgent,
  updateAgent,
  fetchAgentById,
  fetchAgents,
  fetchAgentByOrgId,
} from "../../api/agentApi";
import { getPrompt, getVoice } from "../../api/promptApi";
import { fetchActions } from "../../api/actionApi";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import CreateActionPage from "../actions/EditAction";
import GlobalModal from "../../components/GlobalModal";
import ImportPhoneNumberModal from "../phoneNumber/ImportPhoneNumberModalNew";
import Label from "../../components/Label";
import CollapsibleUI from "./CollapsibleUI";

const { Option } = Select;

const EditAgentDrawer = () => {
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const docId = searchParams.get("docId");
  const agentId = id;
  const [form] = Form.useForm();
  const [voices, setVoices] = useState([]);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const audioRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selecteVoiceId, setSelecteVoiceId] = useState("");
  const [agents, setAgents] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [actions, setActions] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [actionType, setActionType] = useState("");
  const { userData } = useAuth();
  const [checked, setChecked] = useState(false);
  const [openCreateAction, setOpenCreateAction] = useState(false);
  const [numbers, setNumbers] = useState(null);
  const { serviceConfig } = useAuth();
  const service = serviceConfig?.service;

  const voicePreview = async () => {
    try {
      const response = await textToVoiceFetch(
        "21m00Tcm4TlvDq8ikWAM",
        "great job."
      );
      const audioBlob = new Blob([response], { type: "audio/mpeg" });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      if (audioRef.current) {
        audioRef.current.src = url;
        audioRef.current.play();
      }
    } catch (error) {
      console.error("Failed to fetch or play the audio preview:", error);
      message.error("Failed to fetch or play the audio preview.");
    }
  };

  const getAllNumbers = async () => {
    const numbersData = await fetchPhoneNumbers(userData.orgId);
    setNumbers(numbersData);
  };

  useEffect(() => {
    setLoading(true);
    getAllNumbers();
    const fetchData = async () => {
      try {
        const voiceData = await fetchVoices();
        setVoices(voiceData);

        const actionsData = await fetchActions(1, 30, userData?.orgId);
        setActions(actionsData.items);

        if (agentId) {
          const agent = await fetchAgentById(agentId);
          setSelectedAgent(agent);

          const promptData = await getPrompt(agent.prompt.id);
          const voiceDetails = await getVoice(agent.voice.id);

          const selectedVoice = voiceData.find(
            (voice) => voice.voice_id === voiceDetails.voiceId
          );

          form.setFieldsValue({
            agentName: agent.name,
            initialMessage: agent.initialMessage,
            voice: selectedVoice ? selectedVoice.name : "",
            language: agent.language,
            promptContent: promptData.content,
            orgName: agent.orgName,
            actions: agent.actions,
          });

          setSelecteVoiceId(voiceDetails.voiceId);

          setSelectedActions(agent?.actions || []);

          setLoading(false);
        } else {
          setLoading(false);
          setSelecteVoiceId(null);
        }
      } catch (error) {
        message.error("Failed to fetch data.");
        console.error("Failed to fetch data:", error);
        setLoading(false);
      }
    };

    const fetchDataFromVapi = async () => {
      try {
        const voiceData = await fetchVoices();
        const actionsData = await fetchActions(1, 30, userData?.orgId);
        setVoices(voiceData);
        setActions(actionsData.items);

        if (agentId) {
          const agent = await fetchAgentById(agentId);
          setSelectedAgent(agent);

          const selectedVoice = voiceData.find(
            (voice) => voice.voice_id === agent.voice.voiceId
          );

          form.setFieldsValue({
            agentName: agent.name,
            initialMessage: agent.firstMessage,
            voice: selectedVoice ? selectedVoice.name : "",
            language: agent?.transcriber?.language,
            promptContent: agent.model.messages[0]?.content,
            orgName: agent.orgName,
            actions: agent.actions,
          });

          setSelecteVoiceId(agent.voice.voiceId);

          let allItems = [];
          let array = agent?.model?.toolIds || [];
          for (let index = 0; index < array?.length; index++) {
            const element = array[index];
            let _item = actionsData?.items?.find((item) => item.id === element);
            allItems.push(_item);
          }

          setSelectedActions(allItems || []);

          setLoading(false);
        } else {
          form.resetFields();
          setLoading(false);
          setSelecteVoiceId(null);
        }
      } catch (error) {
        message.error("Failed to fetch data.");
        console.error("Failed to fetch data:", error);
        setLoading(false);
      }
    };

    if (service === "vapi") {
      fetchDataFromVapi();
    } else {
      fetchData();
    }
  }, [agentId, form, setSelecteVoiceId]);

  const handlePlayPause = (voice) => {
    if (!voice.preview_url) return;

    if (playingVoiceId === voice.voice_id) {
      audioRef.current.pause();
      setPlayingVoiceId(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(voice.preview_url);
      audioRef.current.play();
      setPlayingVoiceId(voice.voice_id);
      audioRef.current.onended = () => setPlayingVoiceId(null);
    }
  };

  const handleAgentCreateOrUpdate = (values) => {
    if (agentId) {
      handleEditAgent(values);
    } else {
      handleCreateAgent(values);
    }
  };

  const generatePrompt = (promptContent, actions) => {
    const externalActions = actions.filter(
      (action) => action.type === "action_external"
    );
    const prompts = externalActions.flatMap((action) => {
      const properties = action.config.inputSchema.properties;
      return Object.keys(properties).map((key) => key);
    });

    const uniquePrompts = [...new Set(prompts)]; // To remove duplicate prompts
    return `${promptContent} and please ask user about ${uniquePrompts.join(
      ", "
    )}.`;
  };

  const handleCreateAgent = async (values) => {
    let reqBody = {
      agentName: values.agentName,
      voiceElevanLabId: selecteVoiceId,
      promptContent: generatePrompt(values?.promptContent, selectedActions),
      initialMessage: values.initialMessage,
      language: values.language,
      actions: selectedActions?.map((action) => action.id),
      orgId: userData?.orgId,
    };

    if (values?.phoneNumber) {
      reqBody.phoneNumberId = values.phoneNumber;
    }

    setLoading(true);

    try {
      await createAgent(reqBody);
      message.success("Agent created successfully!");
      setSelectedAgent(null);
      setSelectedActions([]);
      const data = await fetchAgentByOrgId(userData?.orgId);
      setAgents(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Failed to create agent.");
    }
  };

  const handleEditAgent = async (values) => {
    setLoading(true);
    const reqBody = {
      id: agentId,
      promptId: selectedAgent?.prompt?.id,
      agentName: values?.agentName,
      promptContent: generatePrompt(values?.promptContent, selectedActions),
      initialMessage: values?.initialMessage,
      language: values?.language,
      voiceElevanLabId: selecteVoiceId,
      voiceVoCodeId: selectedAgent?.voice?.id,
      actions: selectedActions?.map((action) => action?.id),
      fireBaseDocId: docId,
    };

    if (values?.phoneNumber) {
      reqBody.phoneNumberId = values.phoneNumber;
    }
    try {
      await updateAgent(reqBody);
      message.success("Agent updated successfully!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Failed to update agent.");
    }
  };

  const handleCancel = () => {
    setSelectedAgent(null);
    form.resetFields();
    setSelectedActions([]);
  };

  const handleActionTypeChange = (value) => {
    setActionType(value);
  };

  const handleAddAction = (value) => {
    const action = actions.find((action) => action.id === value);
    if (action) {
      setSelectedActions([...selectedActions, action]);
    }
  };

  const handleDiscardAction = (id) => {
    setSelectedActions(selectedActions.filter((action) => action.id !== id));
  };

  const fetchUpdatedActions = async (newAction) => {
    setOpenCreateAction(false);
    const actionsData = await fetchActions(1, 30, userData?.orgId);
    setActions(actionsData?.items);
    setSelectedActions([...selectedActions, newAction]);
  };

  return (
    <div className="">
      <GlobalModal
        noFooter
        visible={openCreateAction}
        onCancel={() => setOpenCreateAction(!openCreateAction)}
        requiredPermission={"canCreateActions"}
      >
        <CreateActionPage fetchUpdatedActions={fetchUpdatedActions} />
      </GlobalModal>
      <div className="w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full h-[500px]">
            <Spin />
          </div>
        ) : (
          <Form
            form={form}
            onFinish={handleAgentCreateOrUpdate}
            layout="vertical"
            requiredMark={false}
          >
            {/* <Typography.Title level={2}>Persona</Typography.Title>
            <Typography.Paragraph>
              Customize your AI Agent’s name, personality, and tone of voice.
            </Typography.Paragraph> */}

            <div className="flex">
              <div>
                <Form.Item
                  name="agentName"
                  label={<Label required>Agent name</Label>}
                  rules={[
                    { required: true, message: "Please enter the agent name" },
                  ]}
                >
                  <Input placeholder="e.g., Synthia" />
                </Form.Item>

                <Form.Item
                  name="initialMessage"
                  label={<Label required>Welcome message</Label>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the welcome message",
                    },
                  ]}
                >
                  <Input placeholder="e.g. Hey there! I’m Synthia, how can I help you? " />
                </Form.Item>

                <Form.Item
                  name="voice"
                  label={<Label required>Voice</Label>}
                  rules={[{ required: true, message: "Please select a voice" }]}
                >
                  <Select
                    placeholder="Select a voice"
                    dropdownRender={(menu) => (
                      <div>
                        <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                          {voices.map((voice) => (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelecteVoiceId(voice.voice_id);
                                form.setFieldsValue({ voice: voice.name });
                              }}
                              key={voice.voice_id}
                              className={`cursor-pointer hover:text-blue-500 flex justify-between items-center px-4 py-1 ${
                                selecteVoiceId === voice.voice_id
                                  ? "bg-blue-200 rounded-sm "
                                  : ""
                              }`}
                            >
                              <span>{voice.name}</span>
                              <Button
                                icon={
                                  playingVoiceId === voice.voice_id ? (
                                    <PauseCircleOutlined />
                                  ) : (
                                    <PlayCircleOutlined />
                                  )
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlePlayPause(voice);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </Form.Item>
                <Form.Item
                  name="language"
                  label={<Label required>Language</Label>}
                  rules={[
                    { required: true, message: "Please select a language" },
                  ]}
                >
                  <Select placeholder="Select a language">
                    {languages.map((language) => (
                      <Option key={language.value} value={language.value}>
                        {language.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="promptContent"
                  label={<Label required>Prompt</Label>}
                  rules={[
                    { required: true, message: "Please enter the prompt" },
                  ]}
                >
                  <Input.TextArea rows={10} placeholder="e.g. Définissez l'agent de support IA comme empathique, efficace et précis, assurant une communication claire, une résolution de problèmes centrée sur l'utilisateur et le respect de la confidentialité et de la sécurité." />
                </Form.Item>
                <Typography.Text type="secondary" className="text-xs mt-[-20px] mb-4 block">We recommend writing a prompt in the selected language for better results</Typography.Text>
               
                <CollapsibleUI />

                {numbers?.length > 0 ? (
                  <>
                    <Form.Item
                      label={<Label>Phone Number</Label>}
                      name="phoneNumber"
                    >
                      <Select placeholder="Select a phone number">
                        {numbers?.map((num) => (
                          <Option key={num.id} id={num.id}>
                            {num.name} ({num.number})
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
{/* 
                    <div className="w-full flex items-end justify-end">
                      <ImportPhoneNumberModal
                        type="link"
                        text="Add New number"
                        fetchNumbers={getAllNumbers}
                      />
                    </div> */}
                  </>
                ) : (
                  <ImportPhoneNumberModal fetchNumbers={getAllNumbers} />
                )}

                <Checkbox
                  className="mb-6"
                  checked={checked}
                  onChange={(e) => setChecked(!checked)}
                >
                  Create an action to save user data or redirect calls during a
                  call
                </Checkbox>

                {checked || selectedActions?.length > 0 ? (
                  <div>
                    {actions && actions?.length > 0 && (
                      <>
                        {/* <Form.Item name="actionType" label="Action Type">
                          <Select
                            placeholder="Select an action type"
                            onChange={handleActionTypeChange}
                          >
                            <Option value="action_external">
                              Collect user data
                            </Option>
                            <Option value="action_transfer_call">
                              Call redirection
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="action" label="Action">
                          <Select
                            placeholder="Select an action"
                            onChange={handleAddAction}
                          >
                            {actions
                              ?.filter((action) => action?.type === actionType)
                              .map((action) => (
                                <Option key={action?.id} value={action?.id}>
                                  {action?.type === "action_transfer_call"
                                    ? `${action.config.phoneNumber}`
                                    : action?.config?.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item> */}
                        {selectedActions?.length > 0 && (
                          <div>
                            <Typography.Title level={4}>
                              Selected Actions
                            </Typography.Title>
                            {selectedActions?.map((action) => (
                              <Card key={action?.id} className="mb-2">
                                <Typography.Text strong>
                                  {action?.type === "action_transfer_call"
                                    ? `${action?.config?.phoneNumber}`
                                    : action?.config?.name}
                                </Typography.Text>
                                {action?.type === "action_external" && (
                                  <div>
                                    <Typography.Title level={5}>
                                      Input Schema
                                    </Typography.Title>
                                    {action?.config.inputSchema?.properties &&
                                      Object.entries(
                                        action?.config.inputSchema.properties
                                      ).map(([name, schema]) => (
                                        <div key={name}>
                                          <Typography.Text>
                                            {name} ({schema.type})
                                          </Typography.Text>
                                        </div>
                                      ))}
                                  </div>
                                )}

                                {action?.service === "vapi" ||
                                action?.type === "transferCall" ||
                                action?.type === "function" ? (
                                  <div>
                                    <Typography.Title level={5}>
                                      {action?.function?.name}
                                    </Typography.Title>

                                    {action?.function.parameters?.properties &&
                                      Object.entries(
                                        action?.function.parameters?.properties
                                      ).map(([name, schema]) => (
                                        <div key={name}>
                                          <Typography.Text>
                                            {name} ({schema.type})
                                          </Typography.Text>
                                        </div>
                                      ))}
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <Button
                                  type="link"
                                  danger
                                  onClick={() => handleDiscardAction(action.id)}
                                >
                                  Discard
                                </Button>
                              </Card>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                    <Button
                      type="link"
                      className="mb-4"
                      onClick={() => setOpenCreateAction(true)}
                    >
                      Add New Action
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {/* <Divider type="vertical" className="h-auto mx-4" />

              <div className="flex-1 pl-8 flex flex-col justify-start">
                <Typography.Text>
                  Type an example message to see how it would be phrased:
                </Typography.Text>
                <Card className="mt-4 p-4">
                  <Input.TextArea
                    rows={4}
                    placeholder="Hi there! I’m the LeadsMotion chatbot. How may I help you today?"
                    bordered={false}
                  />
                  <Button
                    disabled
                    onClick={voicePreview}
                    type="primary"
                    className="mt-2"
                  >
                    Generate preview
                  </Button>
                </Card>
                <audio ref={audioRef} controls style={{ display: "none" }}>
                  <source src={audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div> */}
            </div>

            {/* <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Space>
            </Form.Item> */}
          </Form>
        )}
      </div>
    </div>
  );
};

export default EditAgentDrawer;
