// src/components/GlobalDeleteModal.js
import React from "react";
import { Modal, Typography, Button } from "antd";
import { useAuth } from "../AuthContext";
import "./GlobalModal.css";

const GlobalDeleteModal = ({
  visible,
  onOk,
  onCancel,
  loading,
  requiredPermission,
  title,
}) => {
  const { plan, userData } = useAuth();

  // Function to check if the required permission exists in the permissions array
  const hasPermission = () => {
    return plan?.permissions?.some(
      (permission) => permission.name === requiredPermission
    );
  };

  const isAuthorized = userData.roleId === "owner" || hasPermission();

  return (
    <Modal
      title={isAuthorized ? title : "Not Authorized"}
      visible={visible}
      onOk={isAuthorized ? onOk : onCancel}
      onCancel={onCancel}
      confirmLoading={loading}
      footer={
        isAuthorized
          ? [
              <Button key="back" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={onOk}
              >
                Confirm
              </Button>,
            ]
          : null
      }
    >
      {isAuthorized ? (
        <Typography.Text>
          Are you sure you want to delete this item?
        </Typography.Text>
      ) : (
        <div className="blurred-content">
          <Typography.Text>
            You are not authorized to perform this action.
          </Typography.Text>
        </div>
      )}
    </Modal>
  );
};

export default GlobalDeleteModal;
